import { Routes, Route, Navigate } from "react-router-dom";
import { ROUTES } from "./constants/_routes";

import SignIn from "./modules/Authentications/SignIn/SignIn";
import SignUp from "./modules/Authentications/SignUp/SignUp";
import Global from "./modules/Global/Global";
import Logout from "./modules/Authentications/Logout/Logout";
import Paymob from "./modules/Paymob/Paymob";
import PostPay from "./modules/Paymob/PostPay";
import About from "./modules/About/About";
import LayoutWithNav from "./modules/Layouts/LayoutWithNav";
import PrivacyPolicy from "./modules/PrivacyPolicy";
import LuckyHour from "./modules/LuckyHour";
import StoreItems from "./modules/StoreItems";
import Bidders from "./modules/Bidders";
import AuctionChat from "./modules/Bidders/AuctionChat";
import BuyAndSupply from "./modules/BuyAndSupply";
import Buy from "./modules/BuyAndSupply/Buy";
import Supply from "./modules/BuyAndSupply/Supply";

function RouterFile() {
  return (
    <Routes>
      <Route element={<LayoutWithNav />}>
        <Route path={ROUTES.GLOBAL} element={<Global />} />
        <Route path={ROUTES.ABOUT} element={<About />} />
        <Route path={ROUTES.LUCKYHOURS} element={<LuckyHour />} />
        {/* <Route path={ROUTES.BIDDERS} element={<Bidders />} /> */}
        {/* <Route path={`${ROUTES.BIDDERS}${ROUTES.AUCTION}`} element={<AuctionChat />} /> */}
        <Route path={ROUTES.STORE} element={<StoreItems />} />
        <Route path={ROUTES.BUYANDSUPPLY} element={<BuyAndSupply />} >
          <Route path={""} element={<Navigate to={ROUTES.BUY} />} />
          <Route path={ROUTES.BUY} element={<Buy />} />
          <Route path={ROUTES.SUPPLY} element={<Supply />} />
        </Route>
      </Route>
      <Route path={ROUTES.SIGNIN} element={<SignIn />} />
      <Route path={ROUTES.PRIVACYPOLICY} element={<PrivacyPolicy />} />
      <Route path={ROUTES.SIGNUP} element={<SignUp />} />
      <Route path={ROUTES.LOGOUT} element={<Logout />} />
      <Route path={ROUTES.PAYMOB} element={<Paymob />} />
      <Route path={ROUTES.POSTPAY} element={<PostPay />} />
    </Routes>
  );
}
export default RouterFile;
