import { useState, useEffect, useRef } from "react";
import { Button, Form, InputNumber, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import autoAnimate from '@formkit/auto-animate';
import { handleAllowNumbersOnly } from "../../../helpers/helpfulFunctions";
import { getPaymentTypesApi, placeBuyOrder } from "../../../network";
import { toastError, toastSuccess } from "../../../helpers/toasters";
import { ROUTES } from "../../../constants/_routes";

// assets
import dropdownArrowIcon from "../../../assets/icons/blue-dropdown-arrow.svg"
import closeIcon from "../../../assets/icons/buy-and-supply-close-button.svg"

// components
import Invoice from "../../Common/Invoice";

const BuyModal = ({
    open,
    onCancel,
    selectedItem,
    onSubmit
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const [orderTypes, setOrderTypes] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [currentStep, setCurrentStep] = useState(1); // 1 --> Buy, 2 --> Invoice 
    const [payload, setPayload] = useState(null);
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    const navigate = useNavigate();
    const parent = useRef(null);
    const initialValues = {
        quantity: "",
        paymentMethod: undefined
    }

    const handleUpdatingTotalPrice = (quantity) => {
        const newTotalprice = quantity * selectedItem.pricePerKilo;
        setTotalPrice(newTotalprice)
    }

    const validateQuantity = (_, value) => {
        if (!value && value !== 0) {
            setTotalPrice(0)
            return Promise.reject(t("buyAndSupply.requiredQuantityMsg"))
        } else if (isNaN(value) || value <= 0) {
            setTotalPrice(0)
            return Promise.reject(t("buyAndSupply.invalidQuantityMsg"))
        } else if (value > selectedItem.quantityPerKilo) {
            setTotalPrice(0)
            return Promise.reject(t("buyAndSupply.exceedingAvailableQuantityMsg"))
        } else {
            handleUpdatingTotalPrice(value)
            return Promise.resolve()
        }
    }

    const handleNextStep = (values) => {
        const paymentTypeName = orderTypes.find((ele) => ele.value === values.paymentMethod)?.label
        let payloadObj = {
            paymentTypeId: values.paymentMethod,
            paymentTypeName,
            payItemRequest: {
                itemId: selectedItem.itemId,
                unitId: 1,
                quantity: values.quantity,
            },
        };
        setPayload(payloadObj);
        setCurrentStep(2);
    }

    const handleFinish = () => {
        if (isSubmitting) return
        setIsSubmitting(true);

        placeBuyOrder(
            payload,
            (res) => {
                setIsSubmitting(false)
                if (res.success) {
                    if (payload.paymentTypeId === 2) {
                        navigate(ROUTES.PAYMOB, {
                            state: { paymentURL: res.data.paymentURL },
                        });
                    } else {
                        onSubmit();
                        toastSuccess(res.message)
                    }
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setIsSubmitting(false)
                toastError(res.message)
            }
        )
    }

    useEffect(() => {
        form
            .validateFields({
                validateOnly: true,
            })
            .then(
                (info) => {
                    setSubmittable(true);
                },
                (info) => {
                    if (info.errorFields.length === 0) {
                        setSubmittable(true);
                    } else {
                        setSubmittable(false);
                    }
                }
            );
    }, [values, form]);

    useEffect(() => {
        getPaymentTypesApi(
            (res) => {
                if (res.success) {
                    const options = res.data.map((ele) => {
                        return {
                            value: ele.id,
                            label: ele.name
                        }
                    })
                    setOrderTypes(options);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                toastError(res.message);
            }
        );
    }, [])

    useEffect(() => {
        parent.current && autoAnimate(parent.current)
    }, [parent, parent.current])

    return (
        <Modal
            centered
            destroyOnClose={true}
            open={open}
            footer={false}
            closable={true}
            maskClosable={false}
            closeIcon={<img src={closeIcon} alt="" className="d-block img-fluid" />}
            width={window.innerWidth >= 1440 ? "70%" : window.innerWidth >= 992 ? "55%" : window.innerWidth >= 768 ? "75%" : "100%"}
            className="buy-and-supply__buy-modal"
            onCancel={onCancel}
        >
            <div ref={parent}>
                {currentStep === 1 ? (
                    <div dir={dir}>
                        <p className="text-center fsize-8 main-dark-text fw-bold mb-0 mb-lg-3">
                            {t("buyAndSupply.enterQuantityMsg")}
                        </p>
                        <Form
                            dir={dir}
                            form={form}
                            className="payment-details-form"
                            onFinish={handleNextStep}
                            initialValues={initialValues}
                        >
                            <div dir={dir} className='auction-requests__accept-reject-modal__input-holder mx-auto'>
                                <div>
                                    <p dir={dir} className='text--secondary-8 fw-bold fsize-8 mb-1'>{t("buyAndSupply.quantity")}</p>
                                    <Form.Item
                                        name="quantity"
                                        rules={[{ validator: validateQuantity }]}
                                        className='flex-fill'
                                    >
                                        <InputNumber
                                            keyboard={false}
                                            controls={false}
                                            placeholder={"X"}
                                            onChange={handleUpdatingTotalPrice}
                                            addonAfter={<span className="fw-bold fsize-5 text--secondary-8">{t("buyAndSupply.kg")}</span>}
                                            className="auction-requests__accept-reject-modal__input-holder__input d-block w-100 fsize-8 fw-bold"
                                            onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <p dir={dir} className='text--secondary-8 fw-bold fsize-8 mb-1'>{t("buyAndSupply.paymentMethod")}</p>
                                    <Form.Item
                                        name="paymentMethod"
                                        rules={[
                                            { required: true, message: t("buyAndSupply.requiredPaymentMethod") }
                                        ]}
                                        className='flex-fill'
                                    >
                                        <Select
                                            name="paymentMethod"
                                            className="buy-and-supply__buy-modal__select fsize-5 fw-bold"
                                            loading={orderTypes ? false : true}
                                            suffixIcon={<img src={dropdownArrowIcon} alt="" className="d-block img-fluid" />}
                                            getPopupContainer={trigger => trigger.parentElement}
                                        >
                                            {orderTypes?.map((type) => (
                                                <Select.Option
                                                    className={`buy-and-supply__buy-modal__select__option fsize-9 ${dir === "rtl" ? "text-end" : "text-start"}`}
                                                    value={type.value}
                                                    key={type.value}
                                                >
                                                    {type.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <p className="pt-2 pt-lg-4 d-flex justify-content-between align-items-center gap-2 green-text fw-500 fsize-5">
                                    <span>{t("buyAndSupply.totalPriceBuy")}</span>
                                    <span>{totalPrice} {t("buyAndSupply.egp")}</span>
                                </p>
                                <div className="">
                                    <Button
                                        className="auction-requests__accept-reject-modal__confirm-btn w-100 fsize-9 fw-bold h-auto mx-auto d-block text-white py-2"
                                        htmlType="submit"
                                        disabled={!submittable || isSubmitting}
                                        loading={isSubmitting}
                                    >
                                        {t("auctionRequests.confirm")}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                ) : (
                    <Invoice
                        item={selectedItem}
                        tab="buyAndSupply"
                        payload={payload}
                        totalPrice={totalPrice}
                        isSubmitting={isSubmitting}
                        onBack={() => {
                            setCurrentStep(1)
                        }}
                        onSubmit={handleFinish}
                    />
                )}
            </div>
        </Modal>
    );
}

export default BuyModal;