const LanguageIcon = () => {
    return (
        <svg className="recycle-nav__nav-icon" width="24" height="24" viewBox="0 0 41 40" fill="#d3d3d3" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_354_7951)">
                <path d="M20.0381 0C9.02566 0 0.0380859 8.98828 0.0380859 20C0.0380859 31.0124 9.02637 40 20.0381 40C31.0505 40 40.0381 31.0117 40.0381 20C40.0381 8.98758 31.0498 0 20.0381 0ZM14.1047 3.37055C12.7368 5.11852 11.7494 7.28094 11.0676 9.375H5.94605C8.00457 6.65148 10.833 4.54141 14.1047 3.37055ZM4.44848 11.718H10.4137C9.89152 13.9384 9.58332 16.3423 9.50934 18.8281H2.42168C2.58996 16.2729 3.30395 13.8646 4.44848 11.718ZM2.42168 21.1719H9.50934C9.58238 23.6287 9.88457 26.0052 10.3957 28.2031H4.40715C3.28684 26.077 2.58793 23.6962 2.42168 21.1719ZM5.88645 30.5469H11.0421C11.7271 32.6723 12.7234 34.8643 14.1047 36.6295C10.8016 35.4473 7.9498 33.3084 5.88645 30.5469ZM18.8662 37.4558C16.2396 36.5655 14.4525 33.1613 13.516 30.5469H18.8662V37.4558ZM18.8662 28.2031H12.805C12.2568 26.0359 11.9319 23.6533 11.8539 21.1719H18.8662V28.2031ZM18.8662 18.8281H11.8539C11.9329 16.3168 12.2648 13.9069 12.8252 11.7188H18.8662V18.8281ZM18.8662 9.375H13.544C14.4814 6.78734 16.2604 3.42742 18.8662 2.54422V9.375ZM34.1301 9.375H29.0086C28.3269 7.28141 27.3396 5.11883 25.9714 3.37055C29.2432 4.54141 32.0716 6.65148 34.1301 9.375ZM21.21 2.54422C23.8161 3.42758 25.595 6.78797 26.5321 9.375H21.21V2.54422ZM21.21 11.7188H27.251C27.8113 13.9069 28.1433 16.3168 28.2222 18.8281H21.21V11.7188ZM21.21 21.1711H28.2222C28.1443 23.6533 27.8194 26.0359 27.2711 28.2031H21.21V21.1711ZM21.21 37.4558V30.5461H26.5602C25.6243 33.1597 23.8372 36.5653 21.21 37.4558ZM25.9714 36.6295C27.3529 34.8641 28.3492 32.672 29.034 30.5461H34.1897C32.1264 33.3084 29.2746 35.4473 25.9714 36.6295ZM35.669 28.2031H29.6805C30.1917 26.0052 30.4939 23.6287 30.5669 21.1711H37.6546C37.4882 23.6962 36.7893 26.077 35.669 28.2031ZM37.6545 18.8281H30.5668C30.4929 16.3423 30.1846 13.9384 29.6625 11.7188H35.6277C36.7722 13.8646 37.4862 16.2729 37.6545 18.8281Z" />
            </g>
            <defs>
                <clipPath id="clip0_354_7951">
                    <rect width="40" height="40" fill="white" transform="translate(0.0380859)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default LanguageIcon;