import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "antd";

// assets
import animationImg from "../../assets/images/invoice-animation.gif"

const Invoice = ({
    item,
    tab,
    payload,
    totalPrice,
    isSubmitting,
    onBack,
    onSubmit,
}) => {
    const [totalPriceAfterFees, setTotalPriceAfterFees] = useState("");
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    useEffect(() => {
        if (tab === "buyAndSupply" && payload.paymentTypeId !== 1 && totalPrice) {
            const priceAfterFees = totalPrice + (totalPrice * 2.5 / 100);
            setTotalPriceAfterFees(priceAfterFees.toFixed(2))
        }
    }, [payload, totalPrice, tab])

    return (
        <div dir={dir} className="invoice">
            <div className="invoice__animation-holder mx-auto">
                <img src={animationImg} alt="" className="d-block img-fluid" />
            </div>
            <h1 className="text-center fsize-8 fw-bold">{t("invoice.invoice")}</h1>
            <div className="px-md-4 px-lg-1 px-xl-5">
                <div className="invoice__data-holder">
                    <ul className="m-0 p-0 list-unstyled d-flex flex-column">
                        <li className="d-flex align-items-center justify-content-between">
                            <span className="invoice__data-holder__title fsize-9">{t("invoice.itemName")}</span>
                            <span className="invoice__data-holder__value fsize-11">{item?.itemName || item?.description}</span>
                        </li>
                        <li className="d-flex align-items-center justify-content-between">
                            <span className="invoice__data-holder__title fsize-9">{t("invoice.itemType")}</span>
                            <span className="invoice__data-holder__value fsize-11">{item?.categoryName}</span>
                        </li>
                        <li className="d-flex align-items-center justify-content-between">
                            <span className="invoice__data-holder__title fsize-9">{t("invoice.paymentMethod")}</span>
                            <span className="invoice__data-holder__value fsize-11">{payload?.paymentTypeName}</span>
                        </li>
                        {payload?.paymentTypeId !== 1 ? (
                            <li className="d-flex align-items-center justify-content-between">
                                <span className="invoice__data-holder__title fsize-9">{t("invoice.paymobFees")}</span>
                                <span className="invoice__data-holder__value fsize-11">2.5%</span>
                            </li>
                        ) : (
                            ""
                        )}
                        <li className="d-flex align-items-center justify-content-between">
                            <span className="invoice__data-holder__title fsize-9">{t("invoice.quantity")}</span>
                            <span className="invoice__data-holder__value fsize-11">{payload?.payItemRequest.quantity}</span>
                        </li>
                        <li className="d-flex align-items-center justify-content-between mb-3">
                            <span className="invoice__data-holder__title fsize-9">{t("invoice.unitPrice")}</span>
                            <span className="invoice__data-holder__value fsize-11">{tab === "buy" ? item?.itemUnits[0].price : item?.pricePerKilo} {t("buyAndSupply.egp")}</span>
                        </li>
                        <li className="d-flex align-items-center justify-content-between">
                            <span className="invoice__data-holder__title fsize-9 fw-bold">{t("invoice.totalPrice")}</span>
                            <span className="invoice__data-holder__value--total-price fw-bold fsize-11">{payload?.paymentTypeId === 1 || tab === "buy" ? totalPrice : totalPriceAfterFees} {t("buyAndSupply.egp")}</span>
                        </li>
                    </ul>
                </div>
                <div className="row m-0">
                    <div className={`col-6 ${dir === "ltr" ? "ps-0 pe-3 pe-lg-4" : "pe-0 ps-3 ps-lg-4"}`}>
                        <Button
                            onClick={onBack}
                            className="invoice__btn invoice__btn--back w-100 fsize-5 border-0 fw-bold h-auto d-block text-white"
                        >
                            {t("invoice.back")}
                        </Button>
                    </div>
                    <div className="col-6 px-0">
                        <Button
                            onClick={onSubmit}
                            loading={isSubmitting}
                            className="invoice__btn invoice__btn--submit w-100 fsize-5 border-0 fw-bold h-auto d-block text-white"
                        >
                            {t(`invoice.${payload?.paymentTypeId === 1 ? "buyItem" : "proceedToCheckout"}`)}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Invoice;