import axios from "axios";
import store from "./store";

// live
export const baseUrl = "https://api.thegreenmatter.com/";

// test
// export const baseUrl = "http://ec2-54-194-113-34.eu-west-1.compute.amazonaws.com:6047/";
// "http://ec2-54-195-141-64.eu-west-1.compute.amazonaws.com:6047/";
// "http://ec2-54-195-141-64.eu-west-1.compute.amazonaws.com:6049/";

let token = null;
export function getServToken(newToken) {
  token = newToken;
}

function getCurrentLang() {
  const state = store.getState();
  const { lang } = state.language;
  return lang;
}

export function postResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multiPart = false
) {
  const lang = getCurrentLang();

  let requestData = {
    method: "post",
    url: baseUrl + path,
    headers: {
      "Accept-Language": lang === "en" ? "en-US" : "ar-EG",

    },
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multiPart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      onFail(fail.response);
      if (fail.response.status === 401) {
        window.location.replace("/logout")
      }
      // responseErrorHandeler(fail?.response?.status);
    });
}

export function putResource(
  path,
  data,
  onSuccess,
  onFail,
  reqAuth = true,
  multipart = false
) {
  const lang = getCurrentLang();

  let requestData = {
    method: "put",
    url: baseUrl + path,
    headers: {
      "Accept-Language": lang === "en" ? "en-US" : "ar-EG",
    },
    data,
  };

  if (token && reqAuth) {
    requestData.headers = {
      ...requestData.headers,
      Authorization: "Bearer " + token,
    };
  }

  if (multipart) {
    requestData.headers = {
      ...requestData.headers,
      "content-type": "multipart/form-data",
    };
  }

  axios(requestData).then(
    (res) => {
      onSuccess(res.data);
    },
    (fail) => {
      onFail(fail.response);
      if (fail.response.status === 401) {
        window.location.replace("/logout")
      }
      // responseErrorHandeler(fail.response.status);
    }
  );
}

export function getResource(path, onSuccess, onFail, reqAuth = true) {
  const lang = getCurrentLang();

  let requestData = {
    method: "get",
    url: baseUrl + path,
    headers: {},
  };

  if (reqAuth) {
    requestData.headers = {
      "Accept-Language": lang === "en" ? "en-US" : "ar-EG",
      Authorization: "Bearer " + token,
    };
  }

  axios(requestData)
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((fail) => {
      if (fail?.response?.status === 401) {
        window.location.replace("/logout")
      }
      onFail(fail.response);
    });
}
