import { createSlice } from "@reduxjs/toolkit";

export const authorizationSlice = createSlice({
  name: "authorization",
  initialState: {
    authorization: {},
    fbToken: null,
    // renderNavbar: false,
  },
  reducers: {
    login: (state, action) => {
      state.authorization = action.payload;
    },
    logout: (state, action) => {
      state.authorization = {};
    },
    updateFBToken: (state, action) => {
      state.fbToken = action.payload;
    },
    // updateNavbar: (state, action) => {
    //   state.renderNavbar = !state;
    // },
  },
});

const { reducer, actions } = authorizationSlice;
export const { login, logout, updateFBToken } = actions;
export default reducer;
