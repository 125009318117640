import { useState, useEffect } from "react";
import { Button, Form, InputNumber, Modal, Radio, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toastError, toastSuccess } from "../../../helpers/toasters";
import { sendSupplyRequest } from "../../../network";

// assets
import closeIcon from "../../../assets/icons/buy-and-supply-close-button.svg"
import { handleAllowNumbersOnly } from "../../../helpers/helpfulFunctions";

const SupplyModal = ({
    open,
    onCancel,
    selectedItem,
    onSubmit
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const [quantity, setQuantity] = useState(null);
    const [selectedDayId, setSelectedDayId] = useState(null);

    const [totalSupplyPrice, setTotalSupplyPrice] = useState(null);
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    const initialValues = {
        duration: ""
    }
    const validateQuantity = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(t("buyAndSupply.requiredQuantityMsg"))
        } else if (isNaN(value) || value <= 0) {
            return Promise.reject(t("buyAndSupply.invalidQuantityMsg"))
        } else if (value > selectedItem.quantityPerKilo) {
            return Promise.reject(t("buyAndSupply.exceedingAvailableQuantityMsg"))
        } else {
            return Promise.resolve()
        }
    }
    const handleQuantityChange=(e)=>{
setQuantity(e)
    }

    const handleFinish = (values) => {
        if (isSubmitting) return
        setIsSubmitting(true);

        let payload = {
            supplyId: selectedItem.itemId,
            quantityPerkilo:quantity,
            daysPriceId: values.duration
        }

        sendSupplyRequest(
            payload,
            (res) => {
                setIsSubmitting(false)
                if (res.success) {
                    onSubmit();
                    toastSuccess(res.message)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setIsSubmitting(false)
                toastError(res.message)
            }
        )
    }

    useEffect(() => {
        form
            .validateFields({
                validateOnly: true,
            })
            .then(
                (info) => {
                    setSubmittable(true);
                },
                (info) => {
                    if (info.errorFields.length === 0) {
                        setSubmittable(true);
                    } else {
                        setSubmittable(false);
                    }
                }
            );
    }, [values, form]);

useEffect(()=>{
    if(selectedDayId){
const selectedPrice=selectedItem?.pricePerKiloList?.find(el=>el?.id===selectedDayId).price;
setTotalSupplyPrice(selectedPrice*quantity)
}

},[quantity,selectedDayId])

const radioOnChange=(event)=>{
setSelectedDayId(event?.target?.value)
}


    return (
        <Modal
            centered
            destroyOnClose={true}
            open={open}
            footer={false}
            closable={true}
            closeIcon={<img src={closeIcon} alt="" className="d-block img-fluid" />}
            width={window.innerWidth >= 1200 ? "50%" : window.innerWidth >= 768 ? "75%" : "100%"}
            className="buy-and-supply__supply-modal"
            onCancel={onCancel}
        >

            <div dir={dir}>
                <p className="text-center fsize-5 mb-0 mb-md-3 text--secondary-8 fw-500">
                    {t("buyAndSupply.durationToRecieve")}
                </p>
                <div className="px-3">
                            <p dir={dir} className='text--secondary-8 fw-bold fsize-8 mb-1'>{t("buyAndSupply.quantity")}</p>
                           
                                <InputNumber
                                    keyboard={false}
                                    controls={false}
                                    placeholder={"X"}
                                    addonAfter={<span className="fw-bold fsize-5 text--secondary-8">{t("buyAndSupply.kg")}</span>}
                                    className="auction-requests__accept-reject-modal__input-holder__input d-block w-100 fsize-8 fw-bold"
                                    onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    value={quantity}
                                    onChange={(e)=>handleQuantityChange(e)}
                                    maxLength={8}
                                />
                        </div>
                <Form
                    dir={dir}
                    form={form}
                    className="payment-details-form"
                    onFinish={handleFinish}
                    initialValues={initialValues}
                >
                    <div dir={dir}>
                   
                         <p className="fw-500 fsize-9 mb-4 pb-2">{t("buyAndSupply.selectDuration")} <span className="text-danger">*</span></p>
                        <div>
                            <Form.Item
                                name="duration"
                                rules={[{ required: true, message: t("buyAndSupply.requiredDuration") }]}
                            >
                                <Radio.Group name="duration" onChange={radioOnChange}>
                                    <Space direction="vertical" className="buy-and-supply__supply-modal__radio-holder">
                                        {selectedItem?.pricePerKiloList.map((option) => (
                                            <Radio value={option?.id}>
                                                <span className="main-dark-text fw-bold fsize-11">{option.daysMessage}</span><br />
                                                <span className="fsize-12 gray--8">{option.priceMessage}</span>
                                            </Radio>
                                        ))}
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        {totalSupplyPrice>0&&<div className="d-flex justify-content-center align-items-center">
                        <span className="fw-700 fsize-6 text-truncate">{`${t("buyAndSupply.totalPrice")}: ${totalSupplyPrice.toFixed(2)} ${t("buyAndSupply.egp")}`}</span></div>}
                        <div className="pt-4">
                          <Button
                                className="auction-requests__accept-reject-modal__confirm-btn w-100 fsize-9 fw-bold h-auto mx-auto d-block text-white py-2"
                                htmlType="submit"
                                disabled={!submittable || isSubmitting||!quantity}
                                loading={isSubmitting}
                            >
                                {t("buyAndSupply.next")}
                            </Button>  
                        </div>
                    </div>
                </Form>
            </div>
        </Modal>
    );
}

export default SupplyModal;