import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { toastError } from "../../helpers/toasters";
import { getAllItemsApi } from "../../network";

// assets
import emptyState from "../../assets/icons/no-items.svg";

// components
import ExchangeModal from "./ExchangeModal/index";
import ItemModal from "./ItemModal";
import Item from "./Item";

function Items() {
  const [items, setItems] = useState(null);
  const [renderMe, setRenderMe] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExchangeModalOpen, setIsExchangeModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { lang } = useSelector((state) => state.language);
  // const [allowPurchase, setAllowPurchase] = useState(false);
  // const [pusrchaseLoading, setPurchaseLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getItems();
  }, [renderMe, lang]);

  const getItems = () => {
    getAllItemsApi(
      (res) => {
        setLoading(false);
        if (res.success) {
          setItems(res.data);
          // setAllowPurchase(res?.data[0]?.isApprovedPayment);
          setLoading(false);
        } else {
          toastError(res.message);
        }
      },
      (fail) => {
        setLoading(false);
        toastError(fail.message);
      }
    );
  };

  // const handlePurchaseRequest = () => {
  //   if (pusrchaseLoading) return;
  //   setPurchaseLoading(true);
  //   requestPaymentApproval(
  //     (res) => {
  //       setPurchaseLoading(false);
  //       if (res.success) {
  //         toastSuccess(res.message);
  //       } else {
  //         toastError(res.message);
  //       }
  //     },
  //     (res) => {
  //       setPurchaseLoading(false);
  //       toastError(res.message);
  //     }
  //   );
  // };

  const handlePayItem = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleExhchangeItem = (item) => {
    setSelectedItem(item);
    setIsExchangeModalOpen(true);
  };

  const handleCancel = (renderItems) => {
    setIsModalOpen(false);
    if (renderItems) {
      setRenderMe((prev) => !prev);
    }
  };

  const handleCancelExchangeModal = (renderItems) => {
    setIsExchangeModalOpen(false);
    if (renderItems) {
      setRenderMe((prev) => !prev);
    }
  };
  // const handleAddItemsToCart = (e, item, index) => {
  //   if (selectedItem === index && count >= 1 && isAuth) {
  //     const data = {
  //       itemId: item?.itemId,
  //       quantity: count,
  //       unitId: item?.itemUnits[0]?.unitId,
  //     };
  //     addItemToCartApi(
  //       data,
  //       (success) => {
  //         if (success.success) {
  //           // setLoading(false)
  //           toastSuccess(success.message);
  //         } else {
  //           toastError(success.message);
  //         }
  //       },
  //       (fail) => {
  //         toastError(fail.message);
  //       }
  //     );
  //   }
  // };
  // const handleChangeCount = (e, index, type = "plus") => {
  //   if (type === "plus") {
  //     setCount((prev) => prev + 1);
  //   } else {
  //     setCount((prev) => {
  //       if (prev !== 0) {
  //         return prev - 1;
  //       } else {
  //         return 0;
  //       }
  //     });
  //   }
  //   // setSelectedItem(index);
  // };

  return (
    <section className="mct-section items-section d-flex justify-content-center">
      <Spin size="large" className="w-100" spinning={loading}>
        {items ? (
          items.length > 0 ? (
            <div className="items d-flex flex-column">
              {items.map((item) => {
                return (
                  <Item
                    key={item.itemId}
                    item={item}
                    onPayItem={handlePayItem}
                    onExchangeItem={handleExhchangeItem}
                  />
                );
              })}
            </div>
          ) : (
            <div className="d-flex pt-5 flex-column align-items-center">
              <img
                src={emptyState}
                alt=""
                className="empty-state-img d-block mb-4 mb-lg-5"
              />
              <h2 className="fsize-4 text-center mb-0 text-white">
                {t("home.emptyItems")}
              </h2>
            </div>
          )
        ) : (
          ""
        )}
      </Spin>
      {isModalOpen && (
        <ItemModal
          isModalOpen={isModalOpen}
          onCancel={handleCancel}
          selectedItem={selectedItem}
          onNewData={(data) => {
            const updatedSelectedItem = data.find((ele) => ele.itemId === selectedItem.itemId);
            setSelectedItem(updatedSelectedItem)
            setItems(data)
          }}
        />
      )}
      {isExchangeModalOpen && (
        <ExchangeModal
          isModalOpen={isExchangeModalOpen}
          onCancel={handleCancelExchangeModal}
          selectedItem={selectedItem}
        />
      )}
    </section>
  );
}

export default Items;
