import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Form, Input, Modal, Select } from "antd";
import { getPaymentTypesApi, placeOrderForStoreItem } from "../../network";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { validatePhoneNumber } from "../../helpers/helpfulFunctions";
import { ROUTES } from "../../constants/_routes";

// assets
import flagIcon from "../../assets/icons/egypt-flag.svg";
import dropdownIcon from "../../assets/icons/payment-dropdown-arrow.png";

const PaymentMethodModal = ({
    open,
    onCancel,
    selectedItem,
    purchasedQuantity,
    onSubmit
}) => {
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState(1); // 1 --> Cash, 2 --> Credit Card, 3 --> Mobile Wallet
    const [totalPrice, setTotalPrice] = useState(0);
    const [showPhoneInput, setShowPhoneInput] = useState(false);
    const { dir } = useSelector((state) => state.language);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const handlePreventLetters = (e) => {
        if (e.key === "Backspace" || (e.key.startsWith("Arrow") && e.key !== "ArrowUp" && e.key !== "ArrowDown")) return;
        if (/\D+/gi.test(e.key)) {
            e.preventDefault();
        }
    };

    const validatePhone = (_, value) => {
        const phoneRegex = /^[\d]+$/;
        if (!value) {
            return Promise.reject("");
        } else if (!phoneRegex.test(value)) {
            return Promise.reject(new Error(t("home.itemModal.invalidWallet")));
        } else {
            const isValid = validatePhoneNumber(value);
            if (isValid) {
                return Promise.resolve();
            } else {
                return Promise.reject(new Error(t("home.itemModal.invalidWallet")));
            }
        }
    };

    const calculateTotalPrice = () => {
        let price = purchasedQuantity * selectedItem.price;
        if (selectedMethod !== 1) {
            price += price * (2.5 / 100)
        }
        setTotalPrice(price)
    }

    const handlePaymentChange = (value) => {
        setSelectedMethod(value)
        if (value === 4) {
            setShowPhoneInput(true)
        } else {
            setShowPhoneInput(false)
        }
    };

    const handleFinish = (values) => {
        if (submitting) return;
        setSubmitting(true);

        const payload = {
            payItemRequest: {
                storeItemId: selectedItem.itemId,
                quantity: purchasedQuantity
            },
            paymentTypeId: values.method,
        }
        // Money wallet
        if (selectedMethod === 4) {
            payload.mobile = values.phone
        }

        placeOrderForStoreItem(
            payload,
            (res) => {
                setSubmitting(false)
                if (res.success) {
                    if (selectedMethod === 1) {
                        toastSuccess(res.message);
                        onSubmit();
                    } else if (selectedMethod === 2) {
                        navigate(ROUTES.PAYMOB, {
                            state: { paymentURL: res.data.paymentURL },
                        });
                    } else if (selectedMethod === 4) {
                        window.location.href = res.data.paymentURL;
                    }
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                toastError(res.message)
                setSubmitting(false)
            }
        )
    }

    useEffect(() => {
        calculateTotalPrice();
    }, [selectedMethod])

    useEffect(() => {
        getPaymentTypesApi(
            (res) => {
                setLoading(false)
                if (res.success) {
                    setPaymentMethods(res.data);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                setLoading(false)
                toastError(res.message);
            }
        );
    }, [])

    return (
        <Modal
            centered
            className="store-items__payment-type-modal"
            width={
                window.innerWidth >= 1440 ?
                    "60%" : window.innerWidth > 767 ?
                        "70%" : "100%"
            }
            destroyOnClose={true}
            maskClosable={false}
            open={open}
            onCancel={onCancel}
            closable={true}
            footer={false}
        >
            <Form
                form={form}
                onFinish={handleFinish}
                className="payment-details-form px-5"
                initialValues={{ method: 1 }}
            >
                <div dir={dir}>
                    <h1 className="text-center text--secondary-8 fw-bold fsize-5 mb-5">
                        {t("storeItems.selectPaymentMethod")}
                    </h1>
                    <div>
                        <p className="text--secondary-8 fw-bold fsize-9 mb-1">
                            {t("storeItems.paymentMethod")}
                        </p>
                        <Form.Item
                            name="method"
                            rules={[
                                { required: true, message: "" }
                            ]}
                        >
                            <Select
                                loading={loading}
                                onChange={handlePaymentChange}
                                suffixIcon={<img src={dropdownIcon} alt="" className="d-block img-fluid" />}
                                className="store-items__payment-type-modal__select fw-bold fsize-9"
                            >
                                {paymentMethods?.map((method) => (
                                    <Select.Option
                                        className={dir === "rtl" ? "text-end" : "text-start"}
                                        value={method.id}
                                        key={method.id}
                                    >
                                        {method.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                    {showPhoneInput ? (
                        <div>
                            <p className="text--secondary-8 fw-bold fsize-9 mb-1">
                                {t("home.itemModal.walletNumber")}
                            </p>
                            <Form.Item
                                name="phone"
                                rules={[
                                    { required: true, message: t("home.itemModal.requiredWallet") },
                                    { validator: validatePhone }
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="w-100 store-items__payment-type-modal__phone-input"
                                    onKeyDown={handlePreventLetters}
                                    placeholder="xxxxxxxxxxxx"
                                    prefix={
                                        <div className="d-inline-flex align-items-center gap-1">
                                            <img src={flagIcon} alt="" className="payment-details-form__phone-input__prefix-icon" />
                                            <span className="payment-details-form__phone-input__prefix-text fw-bold">+20</span>
                                        </div>
                                    }
                                />
                            </Form.Item>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="mb-5">
                        <div className="d-flex align-items-center justify-content-between gap-2 fsize-9 fw-bold">
                            <span className="text--secondary-8">{t("storeItems.totalPrice")}</span>
                            <span className="green-text">{`${totalPrice.toFixed(2)} ${t("pendingOrders.egp")}`}</span>
                        </div>
                        {selectedMethod !== 1 && (
                            <p className={`store-items__payment-type-modal__error-msg mb-0 fsize-11 fw-bold ${dir === "ltr" ? "text-end" : "text-start"}`}>
                                {t("storeItems.feesAdded")}
                            </p>
                        )}
                    </div>
                    <div className="px-5">
                        <Button
                            loading={submitting}
                            className="items--store-item__buy-btn d-block w-100 mx-auto border-0 outline-none shadow-none fsize-6 fw-bold text-white text-center px-4"
                            htmlType="submit"
                        >
                            {t("storeItems.buy")}
                        </Button>
                    </div>
                </div>
            </Form>
        </Modal>
    );
}

export default PaymentMethodModal;