import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Drawer } from "antd";
import autoAnimate from '@formkit/auto-animate'
import { doc, setDoc } from "firebase/firestore";
import db from "../../../firebase";

// assets
import profileImg from "../../../assets/images/profile-drawer-icon.png"
import phoneIcon from "../../../assets/icons/profile-phone-icon.png"

const ProfileDrawer = ({ onClose, open, dir, notificationCount }) => {
    const { authorization } = useSelector((state) => state?.auth);
    const { t } = useTranslation();
    const parent = useRef(null)

    useEffect(() => {
        if (authorization && notificationCount > 0) {
            setDoc(doc(db, `users`, authorization?.userId), {
                unReadGroupNotification: 0,
            }, { merge: true });
        }
    }, [authorization, notificationCount])

    useEffect(() => {
        parent.current && autoAnimate(parent.current)
    }, [parent, parent.current])

    return (
        <Drawer
            title={<p dir={dir} className="mb-0">{t("nav.profile")}</p>}
            placement={dir === "ltr" ? "right" : "left"}
            onClose={onClose}
            open={open}
            className={`main-drawer profile-drawer ${dir === "rtl" ? "main-drawer--ar" : ""}`}
            maskClosable={false}
            width={
                window.innerWidth >= 1440
                    ? "45%"
                    : window.innerWidth >= 768
                        ? "50%"
                        : "100%"
            }
        >
            <div
                dir={dir}
                ref={parent}
                className="profile-drawer__main-holder pt-4 d-flex flex-column"
            >
                <div>
                    <img src={profileImg} alt="" className="profile-drawer__profile-icon d-block mx-auto mb-2" />
                    <h2 className="fsize-5 text-center mb-4">{authorization?.fullName}</h2>
                    <div className="d-flex justify-content-center align-items-center gap-3">
                        <img src={phoneIcon} alt="" className="profile-drawer__phone-icon" />
                        <p className="mb-0 fsize-5 fw-bold">{authorization?.mobile}</p>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

export default ProfileDrawer;