import { createSlice } from "@reduxjs/toolkit";

export const LanguageSlice = createSlice({
  name: "language",
  initialState: {
    lang: "en",
    dir: "ltr",
  },
  reducers: {
    changeLang: (state, action) => {
      state.lang = action.payload.lang;
      state.dir =  action.payload.dir;
    },
  },
});

const { reducer, actions } = LanguageSlice;
export const { changeLang } = actions;
export default reducer;
