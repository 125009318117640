import { Button } from "antd";
import { useTranslation } from "react-i18next";

const Offer = ({ offer, item, handleOffer }) => {
  const { t } = useTranslation();
  return (
    <div
      className="orders__order p-2 p-md-3 p-lg-4"
    >
      <h3 className="orders__paragraph text-truncate text-center fw-semibold mb-3 fsize-5">
        {item?.item}
      </h3>
      <div className="orders__description d-flex justify-content-between align-items-center gap-2 mb-3">
        <div>
          <div className="d-flex align-items-center gap-2">
            <span className="orders__qty fsize-9 fw-semibold text-center">
              {t("buyers.weight")}
            </span>
            <span className="fsize-9 text-center">{`${offer?.totalWeight}`}</span>
          </div>
          {/* <div className="d-flex align-items-center gap-2">
            <span className="orders__qty fsize-9 fw-semibold text-center">
              {t("buyers.requestDate")}
            </span>
            <span dir="ltr" className="fsize-9 text-center">{`${offer?.requestdate}`}</span>
          </div> */}
        </div>
        <div className="d-flex flex-column align-items-center gap-1">
          <span className="orders__qty fsize-9 fw-semibold text-center">
            {t("buyers.price")}
          </span>
          <span className="fsize-9 orders__price text-center fw-semibold">
            {offer?.price}
          </span>
        </div>
      </div>
      <div className="row m-0 align-items-center">
        <div className="col-6">
          <Button
            onClick={() => handleOffer({ ...offer, typeId: item.typeId }, true)}
            className={`text-center w-100 py-2 h-auto border-0 orders__order__offer-btn orders__order__offer-btn--accept`}
          >
            {t("notificationModal.accept")}
          </Button>
        </div>
        <div className="col-6">
          <Button
            onClick={() => handleOffer({ ...offer, typeId: item.typeId }, false)}
            className={`text-center w-100 py-2 h-auto border-0 orders__order__offer-btn orders__order__offer-btn--reject`}
          >
            {t("notificationModal.reject")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Offer;
