import { useTranslation } from "react-i18next";

const Order = ({ order }) => {
    const { t } = useTranslation();

    return (
        <div className={`orders__order`}>
            <div className="orders__description">
                <h3 className="orders__paragraph text-truncate fsize-5 fw-bold" title={order?.item}>{order?.item}</h3>
            </div>
            <div className="row m-0"> 
                <div className="col-md-6 px-0 pe-md-2">
                    <div className="d-flex align-items-center gap-2">
                        <span className="orders__paragraph fw-bold fsize-9">{t('pendingOrders.paymentMethod')}</span>
                        <span className="fsize-9 orders__qty fw-500">
                            {order?.paymentType}
                        </span>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <span className="orders__paragraph fw-bold fsize-9">{t('pendingOrders.weight')}</span>
                        <span className="fsize-9 orders__qty fw-500">
                            {order?.remainingQuantity} {t('pendingOrders.kg')}
                        </span>
                    </div>
                </div>
                <div className="col-md-6 px-0 ps-md-2">
                    <div className="d-flex align-items-center gap-2">
                        <span className="orders__paragraph fw-bold fsize-9">{t('pendingOrders.purchasePrice')}</span>
                        <span className="fsize-9 orders__qty fw-500">
                            {order?.unitPrice} {t('pendingOrders.egp')}
                        </span>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <span className="orders__paragraph fw-bold fsize-9">{t('pendingOrders.totalPrice')}</span>
                        <span className="fsize-9 orders__qty fw-500">
                            {order?.price} {t('pendingOrders.egp')}
                        </span>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Order;