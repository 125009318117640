const SignOutIcon = () => {
    return (
        <svg className="recycle-nav__nav-icon" width="24" height="24" viewBox="0 0 41 40" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_354_7947)">
                <path d="M28.8193 30.625V33.75C28.8193 37.1964 26.0157 40 22.5693 40H6.86621C3.41986 40 0.616211 37.1964 0.616211 33.75V6.25C0.616211 2.80365 3.41986 0 6.86621 0H22.5693C26.0157 0 28.8193 2.80365 28.8193 6.25V9.375C28.8193 10.238 28.1199 10.9375 27.2568 10.9375C26.3938 10.9375 25.6943 10.238 25.6943 9.375V6.25C25.6943 4.52698 24.2924 3.125 22.5693 3.125H6.86621C5.14319 3.125 3.74121 4.52698 3.74121 6.25V33.75C3.74121 35.473 5.14319 36.875 6.86621 36.875H22.5693C24.2924 36.875 25.6943 35.473 25.6943 33.75V30.625C25.6943 29.762 26.3938 29.0625 27.2568 29.0625C28.1199 29.0625 28.8193 29.762 28.8193 30.625ZM39.4721 17.316L35.9733 13.8171C35.3629 13.2068 34.3735 13.2068 33.7635 13.8171C33.1531 14.4272 33.1531 15.4166 33.7635 16.0266L36.2522 18.5156H17.4912C16.6282 18.5156 15.9287 19.2151 15.9287 20.0781C15.9287 20.9412 16.6282 21.6406 17.4912 21.6406H36.2522L33.7635 24.1296C33.1531 24.7397 33.1531 25.7291 33.7635 26.3391C34.0687 26.6443 34.4684 26.7969 34.8682 26.7969C35.2683 26.7969 35.6681 26.6443 35.9733 26.3391L39.4721 22.8403C40.9952 21.3171 40.9952 18.8391 39.4721 17.316Z"  />
            </g>
            <defs>
                <clipPath id="clip0_354_7947">
                    <rect width="40" height="40"  transform="translate(0.538086)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default SignOutIcon;