const AboutIcon = () => {
    return (
        <svg className="recycle-nav__nav-icon" width="24" height="24" viewBox="0 0 41 40" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.5031 7.03666C31.4514 4.97636 28.9378 3.43525 26.171 2.54129C23.4043 1.64734 20.4641 1.42635 17.5948 1.89666C13.8941 2.51827 10.4707 4.25283 7.78078 6.86924C5.09083 9.48564 3.26206 12.8596 2.53812 16.5417C1.80875 20.3431 2.306 24.2779 3.95812 27.7783L2.93979 32.8333C2.81005 33.4869 2.84343 34.1624 3.03697 34.7999C3.23051 35.4375 3.57825 36.0176 4.0494 36.4887C4.52056 36.9599 5.1006 37.3076 5.73818 37.5012C6.37577 37.6947 7.05123 37.7281 7.70479 37.5983L12.7531 36.5883C15.187 37.7391 17.8459 38.3351 20.5381 38.3333C21.699 38.3329 22.8573 38.2246 23.9981 38.01C27.6818 37.285 31.0569 35.4544 33.6737 32.7623C36.2905 30.0702 38.0246 26.6444 38.6448 22.9417C39.1153 20.0727 38.8942 17.1329 38 14.3665C37.1057 11.6002 35.564 9.08729 33.5031 7.03666ZM35.3481 22.4283C34.9687 24.7579 34.0442 26.9649 32.6499 28.8694C31.2557 30.7739 29.4312 32.322 27.3252 33.3876C25.2192 34.4532 22.8911 35.0062 20.5309 35.0014C18.1706 34.9966 15.8448 34.4342 13.7431 33.36C13.5091 33.2395 13.2497 33.1766 12.9865 33.1767C12.8745 33.1767 12.7629 33.1879 12.6531 33.21L7.05312 34.33C6.93655 34.3531 6.81609 34.3471 6.70239 34.3125C6.58869 34.278 6.48525 34.2159 6.40122 34.1319C6.31719 34.0479 6.25516 33.9444 6.22061 33.8307C6.18606 33.717 6.18005 33.5966 6.20312 33.48L7.32312 27.8783C7.3972 27.5106 7.34432 27.1287 7.17312 26.795C6.09967 24.6928 5.53799 22.3669 5.53383 20.0065C5.52967 17.6461 6.08315 15.3182 7.14919 13.2123C8.21523 11.1063 9.76366 9.28203 11.6684 7.88795C13.5731 6.49387 15.7801 5.56945 18.1098 5.19C20.4601 4.80736 22.8677 4.98902 25.134 5.71999C27.4002 6.45095 29.4603 7.71028 31.144 9.39407C32.8278 11.0779 34.0872 13.1379 34.8181 15.4042C35.5491 17.6704 35.7308 20.078 35.3481 22.4283Z" />
            <path d="M22.2046 25V18.3333C22.2046 17.8913 22.029 17.4674 21.7164 17.1548C21.4039 16.8423 20.98 16.6667 20.5379 16.6667H18.8713C18.4292 16.6667 18.0053 16.8423 17.6927 17.1548C17.3802 17.4674 17.2046 17.8913 17.2046 18.3333C17.2046 18.7754 17.3802 19.1993 17.6927 19.5118C18.0053 19.8244 18.4292 20 18.8713 20V26.6667C18.8713 27.1087 19.0469 27.5326 19.3594 27.8452C19.672 28.1577 20.0959 28.3333 20.5379 28.3333H22.2046C22.6466 28.3333 23.0705 28.1577 23.3831 27.8452C23.6957 27.5326 23.8713 27.1087 23.8713 26.6667C23.8713 26.2246 23.6957 25.8007 23.3831 25.4881C23.0705 25.1756 22.6466 25 22.2046 25Z"  />
            <path d="M20.5382 15C21.4587 15 22.2049 14.2538 22.2049 13.3333C22.2049 12.4128 21.4587 11.6667 20.5382 11.6667C19.6178 11.6667 18.8716 12.4128 18.8716 13.3333C18.8716 14.2538 19.6178 15 20.5382 15Z"  />
        </svg>
    );
}

export default AboutIcon