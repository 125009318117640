import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button } from "antd";
import moment from "moment";

// assets
import dateIcon from "../../../assets/icons/auction-date-icon.svg"

const AuctionRequest = ({
    data,
    mode,
    onAccept,
    onReject,
}) => {
    const [displayedDate, setDisplayedDate] = useState("");
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    useEffect(() => {
        if (data && mode) {
            let date;
            if (mode === 1) {
                date = data.startDate
            } else {
                date = data.date
            }
            const formattedDate = moment(date, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY - hh:mm:ss A')
            setDisplayedDate(formattedDate)
        }
    }, [data, mode])

    return (
        <article dir={dir} className="bidders__auction auction-requests-drawer__requests-holder__request">
            <h3 className={`green-text text-center fw-bold fsize-8 mb-3`}>{mode === 1 ? data?.auction : data?.auctionName}</h3>
            <p className={`main-dark-text text-center fw-600 fsize-11 ${mode === 1 ? "mb-4 mb-xxl-5" : "mb-2"}`}>{data?.type}</p>
            {mode === 2 ? (
                <p className={`main-dark-text text-center fw-600 fsize-11 mb-4`}>{t("auctionRequests.participatedAmount")}: <span className="green-text">{data?.amount}</span></p>
            ) : (
                ""
            )}
            <ul className="bidders__auction__info-list mx-auto">
                {mode === 2 ? (
                    <li className="fw-bold fsize-11">
                        <span className="pe-1">{`${t("auctionRequests.auctionStatus")}: `}</span>
                        <span className="d-inline-block bidders__auction__minBiddingValue auction-requests-drawer__requests-holder__request__auction-status text-center fw-bold fsize-11">
                            {data?.auctionStatus}
                        </span>
                    </li>
                ) : (
                    <li className="fw-bold fsize-11">
                        {`${t("auctionRequests.maxQuantityPerUser")}: ${data?.maximumAuctionAmountForUserInKilo} ${t("auctionRequests.kg")}`}
                    </li>
                )}
            </ul>
            {mode === 1 ? (
                <div className="d-flex justify-content-center align-items-center gap-3 mb-3">
                    <h4 className="main-dark-text fw-bold fsize-9 mb-0">{t("bidders.minBidding")}</h4>
                    <span className="d-block bidders__auction__minBiddingValue fw-bold fsize-9">
                        {data?.minimumBidding} {t("notificationModal.egp")}
                    </span>
                </div>
            ) : (
                ""
            )}
            <div className="d-flex justify-content-center align-items-center gap-2">
                <img src={dateIcon} alt="" className="bidders__auction__start-date-icon img-fluid d-block" />
                <p className="mb-0 main-dark-text fsize-11 fw-bold">
                    {t(`bidders.${mode === 1 || data?.auctionStatus?.endsWith("coming") ? "start" : "started"}`)} {displayedDate}
                </p>
            </div>
            {mode === 1 ? (
                <div className="d-flex justify-content-center align-items-center gap-3 gap-xl-5 mt-5">
                    <Button
                        type="button"
                        onClick={onReject}
                        className="auction-requests-drawer__requests-holder__request__reject-btn border-0 h-auto outline-none shadow-none fw-bold fsize-9"
                    >
                        <span>{t("auctionRequests.reject")}</span>
                    </Button>
                    <Button
                        type="button"
                        onClick={onAccept}
                        className="bidders__auction__join-btn auction-requests-drawer__requests-holder__request__accept-btn border-0 h-auto outline-none shadow-none fw-bold text-white fsize-9 d-flex align-items-center justify-content-center gap-2"
                    >
                        {t("auctionRequests.accept")}
                    </Button>
                </div>
            ) : (
                ""
            )}
        </article>
    );
}

export default AuctionRequest;