import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Col, Form, Modal, Row, Spin } from "antd";
import { toastError, toastSuccess } from "../../helpers/toasters";
import { acceptOfferByUserByOrderList, getMoneyCollecionTypes, getProfitAndPercentageByOrderList, getUserBalanceInfoByTypeApi } from "../../network";
import Item from "../Items/ExchangeModal/Item";
import { ROUTES } from "../../constants/_routes";
// import { doc, increment, updateDoc } from "firebase/firestore";
// import db from "../../firebase";

function NotificationModal({
  isOpen,
  onCancel,
  selectedItem,
  handleRenderParents,
  onBuyingFromStore
}) {
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingProfit, setLoadingProfit] = useState(false);
  const [profit, setProfit] = useState(0);
  const [profitPercentage, setProfitPercentage] = useState(0);
  const [invalid, setInvalid] = useState(true)
  const [commissionPercentage, setCommissionPercentage] = useState(0);
  const [commission, setCommission] = useState(0);
  const [priceAfterDeduction, setPriceAfterDeduction] = useState(0);
  const [typeItems, setTypeItems] = useState(null);
  const [collectionTypes, setCollectionTypes] = useState(null); // 1 --> Cash, 2 --> Store
  const { dir } = useSelector((state) => state.language);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleInputOnChange = (value, item) => {
    setTypeItems((prevData) => {
      const soldItems = prevData.map((itm) => {
        if (item?.orderId === itm?.orderId) {
          return {
            ...item,
            inputValue: +value
          };
        } else {
          return itm;
        }
      });
      return soldItems;
    });
  };

  const handleSelectItem = (event, orderId) => {
    setTypeItems((prevData) => (
      prevData.map((item) =>
        item.orderId === orderId
          ? {
            ...item,
            isSelected: event.target.checked,
            inputValue:
              event.target.checked ? item.remainingQuantity : 0,
          }
          : item
      )
    ));
  };

  const getProfit = () => {
    setLoadingProfit(true)
    const payload = {
      purchaseRequestId: selectedItem.purchaseRequestId
    }
    const orders = typeItems.filter((item) => item.isSelected).map((item) => {
      return { orderId: item.orderId, sealedQuantity: item.inputValue }
    })
    payload.orders = orders;
    getProfitAndPercentageByOrderList(
      payload,
      (res) => {
        setLoadingProfit(false)
        if (res.success) {
          setProfit(res.data.prophitValue)
          setProfitPercentage(res.data.prophitPercentage)
          setCommissionPercentage(res.data.commissionPercentage)
          setCommission(res.data.greenMatterCommission)
          setPriceAfterDeduction(res.data.sellingPriceAfterCommissionDeduction)
        } else {
          toastError(res.message)
        }
      },
      (res) => {
        setLoadingProfit(false)
        toastError(res.message)
      })
  }

  const handleOnCancel = () => {
    setLoading(false);
    setItem({});
    onCancel();
  };

  const handleBuyingFromStore = () => {
    const payload = {
      purchaseRequestId: item.purchaseRequestId,
      isAccepted: item.isAccepted,
      moneyColleectionTypeId: 2,
      storeItemId: "",
      quantity: 0,
      priceAfterCommissionDeduction: priceAfterDeduction
    };
    const orders = typeItems.filter((item) => item.isSelected).map((item) => {
      return { orderId: item.orderId, sealedQuantity: item.inputValue }
    })
    payload.orders = orders;
    onBuyingFromStore()
    navigate(ROUTES.STORE, { state: { purchaseData: payload } });
  }

  const handleOnFinish = (values) => {
    if (loading || loadingProfit) return;
    setLoading(true);
    const payload = {
      purchaseRequestId: item.purchaseRequestId,
      isAccepted: item.isAccepted,
      moneyColleectionTypeId: 1,
    };

    if (item.isAccepted) {
      const orders = typeItems.filter((item) => item.isSelected).map((item) => {
        return { orderId: item.orderId, sealedQuantity: item.inputValue }
      })
      payload.orders = orders;
    }
    acceptOfferByUserByOrderList(
      payload,
      (success) => {
        if (success?.success) {
          toastSuccess(success.message);
          handleOnCancel();
          handleRenderParents();
        } else {
          setLoading(false);
          toastError(success.message);
        }
      },
      (fail) => {
        toastError(fail.message);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (typeItems) {
      let valid = false;
      for (let item of typeItems) {
        if (item.isSelected) {
          if (item.inputValue && item.inputValue > 0 && item.inputValue <= item.remainingQuantity) {
            valid = true;
          } else {
            valid = false;
            break;
          }
        }
      }
      setInvalid(!valid)
      if (valid) {
        getProfit()
      } else {
        setProfit(0)
        setCommissionPercentage(0)
        setProfitPercentage(0)
        setCommission(0)
      }
    }
  }, [typeItems])

  useEffect(() => {
    if (selectedItem) {
      setItem(selectedItem);
      getUserBalanceInfoByTypeApi(
        selectedItem.typeId,
        (res) => {
          if (res.success) {
            const updatedItems = res.data.items.map((item) => {
              return {
                ...item, isSelected: false, inputValue: item.remainingQuantity
              }
            })
            setTypeItems(updatedItems)
          } else {
            toastError(res.message)
          }
        }, (res) => {
          toastError(res.message);
        })
    }
  }, [selectedItem]);

  useEffect(() => {
    getMoneyCollecionTypes(
      (res) => {
        if (res.success) {
          setCollectionTypes(res.data)
        } else {
          toastError(res.message)
        }
      }, (res) => {
        toastError(res.message);
      })
  }, [])

  return (
    <Fragment>
      <Modal
        centered
        width={selectedItem?.isAccepted ? window.innerWidth >= 992 ? "80%" : "90%" : "auto"}
        className="notificationModal"
        destroyOnClose={true}
        maskClosable={false}
        open={isOpen}
        onCancel={onCancel}
        closable={true}
        footer={false}
      >
        <h2 className="text-center fsize-5 text--blue-2 fw-bold">{t(`notificationModal.${selectedItem?.isAccepted ? "accept" : "reject"}Offer`)}</h2>
        <Form
          className="payment-details-form"
          onFinish={handleOnFinish}
          layout="vertical"
          dir={dir}
          autoComplete="off"
        >
          <Row gutter={24}>
            {selectedItem?.isAccepted && (
              <Col span={24}>
                <div className="notificationModal__items-holder pe-3 d-flex flex-column gap-3">
                  {typeItems ?
                    typeItems.map((item) => (
                      <Item
                        checked={item.isSelected}
                        key={item.orderId}
                        item={item}
                        onSelect={handleSelectItem}
                        onInputChange={handleInputOnChange}
                        type={"accept-offer-item"}
                      />
                    )) :
                    <div className="d-flex py-3 justify-content-center">
                      <Spin />
                    </div>
                  }
                </div>
                <div className="row m-0 pt-3">
                  <div className="col-lg-6">
                    <p className="mb-1 main-dark-text fw-500 fsize-11">{t("notificationModal.profitPercentage")}: {loadingProfit ? <Spin /> : `${!invalid ? profitPercentage : 0} %`}</p>
                    <p className="mb-1 main-dark-text fw-500 fsize-11">{t("notificationModal.profit")}: {loadingProfit ? <Spin /> : `${!invalid ? profit : 0} ${t("notificationModal.egp")}`}</p>
                  </div>
                  <div className="col-lg-6">
                    <p className="mb-1 main-dark-text fw-500 fsize-11">{t("notificationModal.commissionPercentage")}: {loadingProfit ? <Spin /> : `${!invalid ? commissionPercentage : 0} %`}</p>
                    <p className="mb-1 main-dark-text fw-500 fsize-11">{t("notificationModal.commission")}: {loadingProfit ? <Spin /> : `${!invalid ? commission : 0} ${t("notificationModal.egp")}`}</p>
                    <p className="mb-3 main-dark-text fw-500 fsize-11">{t("notificationModal.priceAfterCommissionDeduction")}: {loadingProfit ? <Spin /> : `${!invalid ? priceAfterDeduction : 0} ${t("notificationModal.egp")}`}</p>
                  </div>
                </div>
              </Col>
            )}
            {!selectedItem?.isAccepted && (
              <Col span={24}>
                <p
                  className="mb-3 text-center fsize-9"
                >
                  {t("notificationModal.rejectMsg")}
                </p>
              </Col>
            )}
            {selectedItem?.isAccepted && (
              <>
                <p className="fw-bold fsize-12 dark-gray text-center notificationModal__chooseCollectionTypeMsg mx-auto">
                  {t("notificationModal.chooseCollectionType")}
                </p>
                <div className="w-100 d-flex justify-content-center gap-5 align-items-center pt-2">
                  {collectionTypes?.map((type) => (
                    <Button
                      key={type.id}
                      onClick={() => {
                        if (type.id === 2) {
                          handleBuyingFromStore();
                        }
                      }}
                      htmlType={type.id === 1 ? "submit" : "button"}
                      disabled={!typeItems || (invalid && selectedItem?.isAccepted) || loadingProfit}
                      className={`text-center d-block fsize-5 py-1 px-4 px-lg-5 h-auto fw-500 orders__order__offer-btn orders__order__offer-btn--${type.id === 1 ? "accept" : "store"}`}
                    >
                      {type.name}
                    </Button>
                  ))}
                </div>
              </>
            )}
            {!selectedItem?.isAccepted && (
              <Button
                type="submit"
                htmlType="submit"
                loading={loading}
                className={`text-center d-block fsize-5 py-1 px-4 px-lg-5 mx-auto h-auto fw-500 orders__order__offer-btn orders__order__offer-btn--reject`}
              >
                {t(`notificationModal.reject`)}
              </Button>
            )}
          </Row>
        </Form>
      </Modal>
    </Fragment>
  );
}

export default NotificationModal;
