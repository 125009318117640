import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function LandingPage() {
  const { t } = useTranslation();
  const { dir } = useSelector((state) => state.language);

  return (
    <section dir={dir}>
      <div
        className="landing-page"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className={`landing-title ${dir === "rtl" ? "pe-md-5 pe-4" : ""}`}>
          <h1 className="fsize-2 green-text text-uppercase">{t('home.greenMatter')}</h1>
          <p className="fsize-6 white-text">{t('home.description')}</p>
        </div>
      </div>
    </section>
  );
}

export default LandingPage;
