import { Modal } from "antd";
import { useTranslation } from 'react-i18next';
import OTPVerificationForm from "./OtpInput/OtpVerificationForm";

function GlobalModal({ isModalOpen, onCancel, keepSignedIn, data, name = "" }) {
  const { t } = useTranslation();

  return (
    <Modal
      className={name}
      centered
      title={t("auth.verifyOTP")}
      open={isModalOpen}
      footer={null}
      closable
      onCancel={onCancel}
      maskClosable={false}
    >
      <OTPVerificationForm keepSignedIn={keepSignedIn} data={data} onCancel={onCancel} />
    </Modal>
  );
}

export default GlobalModal;
