import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import videoOverlay from "../../assets/images/about-video-overlay.png"
import videoIcon from "../../assets/icons/videoIcon.svg"

const About = () => {
    const [hideOverlay, setHideOverlay] = useState(false);
    const [removeOverlay, setRemoveOverlay] = useState(false);
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();
    const videoRef = useRef(null);
    let timerId;
    const videoLink = "https://api.thegreenmatter.com/StaticFiles/Videos/The%20Greenmatter.mp4";

    const handleStartVideo = () => {
        setHideOverlay(true);
        timerId = setTimeout(() => setRemoveOverlay(true), 500)
        videoRef.current.play();
    }

    useEffect(() => {
        return () => clearTimeout(timerId)
    }, [])

    return (
        <section className="about-us">
            <div dir={dir} className="about-us__section about-us__first-section container-fluid">
                <h1>
                    <span className="d-block fsize-3 white-text text-uppercase">{t('about.about')}</span>
                    <span className="d-block fsize-1 green-text text-uppercase">{t('about.greenMatter')}</span>
                </h1>
                <p className="fsize-8 white-text mb-3 mb-xxl-5">{t('about.firstSection.1')}</p>
                <p className="fsize-8 white-text mb-0">{t('about.firstSection.2')}</p>
            </div>
            <div dir={dir} className="about-us__section about-us__second-section container-fluid">
                <p className="fsize-8 white-text mb-5">{t('about.secondSection.1')}</p>
                <p className="fsize-8 white-text mb-0">{t('about.secondSection.2')} </p>
            </div>
            <div className="about-us__join-section">
                <div dir={dir} className="text-holder d-flex flex-column justify-content-center">
                    <h2 className="fsize-4 white-text mb-3 mb-md-4 mb-lg-5">{t('about.joinSection.title')}</h2>
                    <p className="fsize-7 white-text mb-0">{t('about.joinSection.description')}</p>
                </div>
            </div>
            <div dir={dir} className="about-us__video-section">
                <div className="row mx-0 align-items-center">
                    <div className="col-md-6 col-lg-5 text-holder mb-3 mb-md-0">
                        <h2 className={`fsize-4 text-center ${dir === "ltr" ? "text-md-start" : "text-md-end pe-3"} white-text mb-3 mb-xxl-5`}>{t('about.videoSection.title')}</h2>
                        <p className={`fsize-7 text-center ${dir === "ltr" ? "text-md-start" : "text-md-end pe-3"} white-text mb-0`}>{t('about.videoSection.description')}</p>
                    </div>
                    <div className={`col-md-6 col-lg-7 ${dir==="ltr"?"ps-md-3 pe-md-4 pe-xxl-5":"pe-md-3 ps-md-4 ps-xxl-5"}`}>
                        <div className="position-relative">
                            <video className="d-block" ref={videoRef} controls>
                                <source src={videoLink} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            {!removeOverlay ? <div className={`video-overlay position-absolute w-100 h-100 top-0 start-0 ${hideOverlay ? "hide" : ""}`}>
                                <img src={videoOverlay} alt="" className="w-100 h-100 object-fit-cover" />
                                <button type="button" onClick={handleStartVideo} className="d-block bg-transparent position-relative w-100 h-100 position-absolute top-0 start-0 outline-0 border-0 d-flex justify-content-center align-items-center">
                                    <img src={videoIcon} alt="" className="video-play-icon" />
                                </button>
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;