import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { getUserPendingOrdersApi } from "../../../network";
import { toastError } from "../../../helpers/toasters";

// components
import Pending from "./Pending";

function PendingModal({ isModalOpen, onCancel }) {
    const { t } = useTranslation();
    const [allPendingOrders, setAllPendingOrders] = useState(null);

    useEffect(() => {
        getUserPendingOrdersApi(
            (res) => {
                if (res.success) {
                    setAllPendingOrders(res.data);
                } else {
                    toastError(res.message);
                }
            },
            (res) => {
                toastError(res.message);
            }
        );
    }, [])

    return (
        <Modal
            centered
            width={window.innerWidth >= 1440 ?
                "60%" : window.innerWidth > 767 ?
                    "70%" : "100%"}
            bodyStyle={{ overflow: 'hidden', maxHeight: 'calc(100vh - 150px)' }}
            open={isModalOpen}
            footer={false}
            closable={true}
            onCancel={onCancel}
        >
            <h2 className="green-text fw-bold fsize-4 text-center pt-4 mb-5">{t("pendingOrders.title")}</h2>
            <Pending allData={allPendingOrders} />
        </Modal>
    );
}

export default PendingModal;