import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Checkbox, InputNumber } from "antd";
import { baseUrl } from "../../../services";
import image from "../../../assets/images/default-product-img.png";

const Item = ({ item, onSelect, checked, onInputChange, type }) => {
  const { dir } = useSelector((state) => state.language);
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState("");

  const handlePreventLetters = (e) => {
    if (e.key === "Backspace" || e.key === "." || e.key === "ArrowLeft" || e.key === "ArrowRight") return;
    if (/\D+/gi.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleWeightChange = (value) => {
    onInputChange(value, item);
    if (+value <= 0 || isNaN(+value)) {
      setErrorMsg("invalidQuantity");
    } else if (+value > item.totalQuantity) {
      setErrorMsg("invalidQuantityNotEnoughBalance");
    } else {
      setErrorMsg("");
    }
  };

  const handleQuantityChange = (value) => {
    onInputChange(value, item);
    if (isNaN(+value)) {
      setErrorMsg("requiredQuantity")
    } else if (+value > item?.remainingQuantity || +value <= 0) {
      setErrorMsg("correctQuantity");
    } else {
      setErrorMsg("");
    }
  };

  return (
    <article className="exchangeModal__item d-flex flex-column flex-md-row align-items-center gap-3 gap-md-4">
      <button
        className="bg-transparent p-0 border-0 outline-0 shadow-none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#input-${type === "accept-offer-item" ? item.orderId : item.itemId}`}
        aria-expanded="false"
        aria-controls={`input-${type === "accept-offer-item" ? item.orderId : item.itemId}`}>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            if (!e.target.checked) {
              setErrorMsg("")
            }
            if (type === "accept-offer-item") {
              onSelect(e, item?.orderId)
            } else {
              onSelect(e, item?.itemId)
            }
          }}
          className="green-text"
        >
          {type === "accept-offer-item" ? t("notificationModal.sellItem") : t('home.exchangeModal.exchangeItem')}
        </Checkbox>
      </button>
      <img
        src={item?.image?.length ? baseUrl + item.image : image}
        alt=""
        className="exchangeModal__item__img d-block object-fit-cover"
      />
      <div className="align-self-stretch flex-fill">
        <div className="row m-0 flex-column flex-md-row justify-content-between align-md-items-center">
          <div className={`col-md-7 ${dir === "ltr" ? "pe-md-3" : "ps-md-3"} px-0 mb-3 mb-md-0`}>
            <h4 className={`exchangeModal__label exchangeModal__item-name text-truncate fw-semibold text-center ${dir === "ltr" ? "text-md-start" : "text-md-end"} fsize-7 mb-0`}>
              {item?.item}
            </h4>
          </div>
          <div className="col-md-5 px-0 mb-3 mb-md-0">
            <div>
              <p className="main-dark-text fw-semibold fsize-9 mb-2 text-center text-md-start">
                {t("home.exchangeModal.totalWeight")}: {item ? type === "accept-offer-item" ? item.remainingQuantity : item.totalQuantity : ""}{" "}{t("home.exchangeModal.kg")}
              </p>
              <p className="main-dark-text fw-semibold fsize-9 mb-0 text-center text-md-start">
                {type === "accept-offer-item" ? t("notificationModal.purchasePrice") : t("home.exchangeModal.price")}: {item ? type === "accept-offer-item" ? item.purchasePrice : item.perKiloPrice.toFixed(2) : ""}{" "}{t("home.exchangeModal.le")}
              </p>
            </div>
          </div>
          <div className="col-lg-7 px-0">
            <div id={`input-${type === "accept-offer-item" ? item.orderId : item.itemId}`} className="collapse">
              <p dir={dir} className={`main-dark-text fw-500 fsize-9 mb-2 text-center ${dir === "rtl" ? "text-md-end" : "text-md-start"}`}>
                {t("home.exchangeModal.weight")}
              </p>
              <InputNumber
                min={0}
                className="w-100 d-block"
                placeholder={t("home.exchangeModal.weight")}
                value={item?.inputValue}
                onChange={(value) => {
                  if (checked) {
                    if (type === "accept-offer-item") {
                      handleQuantityChange(value)
                    } else {
                      handleWeightChange(value);
                    }
                  }
                }}
                disabled={!checked}
                onKeyDown={handlePreventLetters}
                controls={false}
              />
              {checked && errorMsg && (
                <p className="mb-0 text-danger">
                  {type === "accept-offer-item" ? t(`notificationModal.${errorMsg}`) : t(`home.exchangeModal.${errorMsg}`)}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Item;
