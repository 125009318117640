import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const BuyAndSupply = () => {
    const { dir } = useSelector((state) => state.language);

    return (
        <section dir={dir} className="buy-and-supply">
            <Outlet />
        </section>
    );
}

export default BuyAndSupply;