import { toast } from "react-toastify";
import store from "../store/index";

function getCurrentDirection() {
  const state = store.getState();
  const { dir } = state.language;
  return dir;
}

const props = {
  position: "bottom-right",
  autoClose: 5000,
};

export const toastSuccess = (message) => {
  const dir = getCurrentDirection();
  const copyOfProps = { ...props, rtl: dir === "rtl" };
  toast.success(message, copyOfProps);
};

export const toastError = (message) => {
  const dir = getCurrentDirection();
  const copyOfProps = { ...props, rtl: dir === "rtl" };
  if (message) {
    toast.error(message, copyOfProps);
  } else {
    toast.error("An Error Occured", copyOfProps);
  }
};