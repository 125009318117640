import { Modal } from "antd";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

function BalanceModal({ isModalOpen, onCancel, data, title }) {
  const { t } = useTranslation();
  const { dir } = useSelector((state) => state.language);

  return (
    <Modal
      centered
      width={window.innerWidth >= 1440 ?
        "60%" : window.innerWidth > 767 ?
          "70%" : "100%"}
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}
      open={isModalOpen}
      footer={false}
      closable={true}
      onCancel={onCancel}
    >
      <h2 className="green-text fw-bold fsize-4 text-center pt-4 mb-3 mb-xxl-4">{t('balance.myBalance')}</h2>
      <div id="balance" dir={dir} className={`orders ${dir === "rtl" ? "right-direction" : ""}`}>
        {data?.length > 0 ?
          <div className="accordion w-100" id="accordionFlushExample">
            {data.map((ele) =>
            (
              <div className="accordion-item mb-3 mb-md-4 mb-xxl-5" key={ele.categoryId}>
                <h2 className="accordion-header">
                  <button className="accordion-button flex-column gap-2 align-items-start shadow-none" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-${ele.categoryId}`} aria-expanded="false" aria-controls={`#flush-${ele.categoryId}`}>
                    <span className="fw-bold fsize-5">{ele.categoryName}</span>
                    <span className="fsize-9">{t('balance.totalQuantity')}: {ele.totalQuantity} {t('balance.kg')}</span>
                    <span className="fsize-9">{t('balance.totalPurchasePrice')}: {(+(+ele.totalPrice).toFixed(2)).toLocaleString()} </span>
                    {ele.totalOfferPrice ? <span className="fsize-9">{t('balance.totalOfferPrice')}: {(+(+ele.totalOfferPrice).toFixed(2)).toLocaleString()}</span> : ""}
                    {ele.totalProphit ? <span className="fsize-9">{t('balance.totalProfit')}: {(+(+ele.totalProphit).toFixed(2)).toLocaleString()}</span> : ""}
                    {ele.prophitPercentage || ele.prophitPercentage === 0 ? <span className="fsize-9">{t('balance.profitPercentage')}: {ele.prophitPercentage}%</span> : ""}
                  </button>
                </h2>
                <div id={`flush-${ele.categoryId}`} className="accordion-collapse collapse show">
                  <div className="accordion-body">
                    <ul className="m-0 list-unstyled">
                      {ele.items.map((item, indx) => (
                        <li className="orders__order" key={`${ele.categoryId}-${item.itemId}-${indx}`}>
                          <div className="orders__description">
                            <h3 className="orders__paragraph text-truncate fsize-5 fw-bold" title={item?.item}>{item?.item}</h3>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <span className="fsize-9 orders__paragraph fw-semibold">{t('balance.quantity')}</span>
                            <span className="fsize-9 orders__paragraph">
                              {item?.remainingQuantity} {t('balance.kg')}
                            </span>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <span className="fsize-9 orders__paragraph fw-semibold">{t('balance.price')}</span>
                            <span className="fsize-9 orders__paragraph">
                              {(+(+item?.purchasePrice)).toLocaleString()}
                            </span>
                          </div>
                          <div className="d-flex align-items-center gap-2">
                            <span className="fsize-9 orders__paragraph fw-semibold">{t('balance.totalPurchasePrice')}:</span>
                            <span className="fsize-9 orders__paragraph">
                              {(+(+item?.totalPurchasePrice).toFixed(2)).toLocaleString()}
                            </span>
                          </div>
                          {item?.totalOfferPrice ?
                            (
                              <div className="d-flex align-items-center gap-2">
                                <span className="fsize-9 orders__paragraph fw-semibold">{t('balance.totalOfferPrice')}:</span>
                                <span className="fsize-9 orders__paragraph">
                                  {(+item.totalOfferPrice).toLocaleString()}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          {item?.totalProphit ?
                            (
                              <div className="d-flex align-items-center gap-2">
                                <span className="fsize-9 orders__paragraph fw-semibold">{t('balance.totalProfit')}:</span>
                                <span className="fsize-9 orders__paragraph">
                                  {(+(item.totalProphit)).toLocaleString()}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          {item?.prophitPercentage ?
                            (
                              <div className="d-flex align-items-center gap-2">
                                <span className="fsize-9 orders__paragraph fw-semibold">{t('balance.profitPercentage')}:</span>
                                <span className="fsize-9 orders__paragraph d-flex">
                                  <span>{(item.prophitPercentage)}</span>
                                  %
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          <div className="d-flex align-items-center gap-2">
                            <span className="fsize-9 orders__paragraph fw-semibold">{t('balance.date')}</span>
                            <span className="fsize-9 orders__paragraph">
                              {item?.purchaseDate}
                            </span>
                          </div>
                        </li>)
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
          : (
            <h4>{t(`balance.noData`)}</h4>
          )}
      </div>
    </Modal>
  );
}

export default BalanceModal;
