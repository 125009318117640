import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import Offer from "./Offer";

const OfferDrawer = ({
  data,
  onOffersClose,
  isOffersVisible,
  dir,
  handleOffer,
}) => {
  const { t } = useTranslation();
  return (
    <Drawer
      title={t("buyers.title")}
      style={{ textAlign: dir === "rtl" ? "end" : "start" }}
      placement="right"
      width={
        window.innerWidth >= 992
          ? "45%"
          : window.innerWidth >= 768
            ? "50%"
            : "100%"
      }
      onClose={onOffersClose}
      open={isOffersVisible}
      className={"offers-drawer"}
    >
      {data?.length > 0 ? (
        <div className="orders pb-4 pt-4">
          {data.map((type) => (
            <div key={type.typeId} dir={dir} className="w-100">
              <h2 className="orders__category position-relative d-flex align-items-center gap-2 text-white fsize-5">
                {type.typeName}
              </h2>
              <div className="d-flex flex-column gap-3">
                {type.items.map((item) => (
                  <div key={item?.itemId}>
                    <Offer
                      offer={item?.highestOffer}
                      item={item}
                      handleOffer={handleOffer}
                    />
                    {item.offers.length > 1 ? <div
                      className="accordion px-3 w-100 mt-2"
                      id="offers-accordion"
                    >
                      <div className="accordion-item border-0">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button p-2 bg-transparent gap-2 align-items-center justify-content-between shadow-none collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#offer-${type.typeId}-${item?.itemId}`}
                            aria-expanded="false"
                            aria-controls={`#offer-${type.typeId}-${item?.itemId}`}
                          >
                            <span className="fsize-9 d-block text-truncate text-white">
                              {t("buyers.moreOffers")} {item?.item}
                            </span>
                          </button>
                        </h2>
                        <div
                          id={`offer-${type.typeId}-${item?.itemId}`}
                          className="accordion-collapse collapse"
                        >
                          <div className="accordion-body p-2">
                            <ul className="m-0 list-unstyled d-flex flex-column gap-1">
                              {
                                item.offers.map((ofr, index) => {
                                  return index !== 0 ? (
                                    <li key={ofr?.purchaseRequestId}>
                                      <Offer
                                        offer={ofr}
                                        item={item}
                                        handleOffer={handleOffer}
                                      />
                                    </li>
                                  ) : null;
                                })
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                      : ""
                    }

                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <h2 className="white-text text-center pt-5 pe-4">{t("buyers.noData")}</h2>
      )}
    </Drawer>
  );
};

export default OfferDrawer;
