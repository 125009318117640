import { useRef } from "react";
import { useLocation } from "react-router-dom";

function Paymob() {
  const location = useLocation();
  const iframeRef = useRef(null);
  
  return (
    <section
      className="mct-section pb-4 mct-section-signin d-flex align-items-center justify-content-center"
      style={{ hight: "100vh" }}
    >
      <iframe
        ref={iframeRef}
        src={location?.state?.paymentURL}
        frameBorder="0"
        title="Paymob"
        // width="100%"
        allowFullScreen
        scrolling="no"

      ></iframe>
    </section>
  );
}

export default Paymob;
