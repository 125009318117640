const ContactUsIcon = () => {
    return (
        <svg className="recycle-nav__nav-icon" width="24" height="24" viewBox="0 0 41 40" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.5818 28.2425C37.5156 28.1875 30.0206 22.8475 27.9968 23.1812C27.0206 23.3538 26.4631 24.02 25.3443 25.3525C25.1643 25.5675 24.7306 26.0813 24.3956 26.4475C23.6885 26.2171 22.9988 25.9364 22.3318 25.6075C18.8887 23.9313 16.1068 21.1493 14.4306 17.7062C14.1017 17.0393 13.8209 16.3496 13.5906 15.6425C13.9581 15.3062 14.4731 14.8725 14.6931 14.6875C16.0193 13.575 16.6843 13.0162 16.8568 12.0387C17.2106 10.015 11.8506 2.5225 11.7956 2.455C11.5515 2.10882 11.2336 1.82116 10.8648 1.61277C10.496 1.40439 10.0856 1.28048 9.66309 1.25C7.49059 1.25 1.28809 9.295 1.28809 10.6512C1.28809 10.73 1.40184 18.735 11.2731 28.7762C21.3031 38.6362 29.3081 38.75 29.3868 38.75C30.7418 38.75 38.7881 32.5475 38.7881 30.375C38.7573 29.9525 38.6331 29.542 38.4245 29.1732C38.2159 28.8045 37.9281 28.4866 37.5818 28.2425ZM29.2481 36.2425C28.1631 36.15 21.4381 35.2638 13.0381 27.0125C4.74684 18.5713 3.88309 11.835 3.79684 10.7913C5.43531 8.21955 7.41408 5.88128 9.67934 3.84C9.72934 3.89 9.79559 3.965 9.88059 4.0625C11.6179 6.43404 13.1144 8.97284 14.3481 11.6412C13.9469 12.0448 13.5229 12.4251 13.0781 12.78C12.3883 13.3056 11.7549 13.9013 11.1881 14.5575C11.0922 14.6921 11.0239 14.8443 10.9872 15.0055C10.9505 15.1666 10.9461 15.3334 10.9743 15.4963C11.2389 16.6422 11.644 17.751 12.1806 18.7975C14.1029 22.745 17.2928 25.9344 21.2406 27.8563C22.2869 28.3936 23.3958 28.7992 24.5418 29.0637C24.7047 29.0919 24.8715 29.0876 25.0326 29.0509C25.1938 29.0142 25.346 28.9459 25.4806 28.85C26.1391 28.2808 26.7369 27.6449 27.2643 26.9525C27.6568 26.485 28.1806 25.8612 28.3793 25.685C31.0545 26.9175 33.599 28.4158 35.9743 30.1575C36.0781 30.245 36.1518 30.3125 36.2006 30.3563C34.1592 32.6222 31.8205 34.6015 29.2481 36.24V36.2425Z"  />
            <path d="M21.2881 11.25C23.2766 11.252 25.1831 12.0428 26.5892 13.4489C27.9953 14.855 28.7861 16.7615 28.7881 18.75C28.7881 19.0815 28.9198 19.3995 29.1542 19.6339C29.3886 19.8683 29.7066 20 30.0381 20C30.3696 20 30.6875 19.8683 30.922 19.6339C31.1564 19.3995 31.2881 19.0815 31.2881 18.75C31.2851 16.0987 30.2306 13.5569 28.3559 11.6822C26.4811 9.8075 23.9393 8.75298 21.2881 8.75C20.9566 8.75 20.6386 8.8817 20.4042 9.11612C20.1698 9.35054 20.0381 9.66848 20.0381 10C20.0381 10.3315 20.1698 10.6495 20.4042 10.8839C20.6386 11.1183 20.9566 11.25 21.2881 11.25Z"  />
            <path d="M21.2881 5C24.9335 5.0043 28.4284 6.45434 31.0061 9.03203C33.5837 11.6097 35.0338 15.1046 35.0381 18.75C35.0381 19.0815 35.1698 19.3995 35.4042 19.6339C35.6386 19.8683 35.9566 20 36.2881 20C36.6196 20 36.9375 19.8683 37.172 19.6339C37.4064 19.3995 37.5381 19.0815 37.5381 18.75C37.5331 14.4418 35.8195 10.3114 32.7731 7.265C29.7267 4.2186 25.5963 2.50496 21.2881 2.5C20.9566 2.5 20.6386 2.6317 20.4042 2.86612C20.1698 3.10054 20.0381 3.41848 20.0381 3.75C20.0381 4.08152 20.1698 4.39946 20.4042 4.63388C20.6386 4.8683 20.9566 5 21.2881 5Z"  />
        </svg>
    );
}

export default ContactUsIcon;