import { useState, useEffect } from "react";
import { Button, Form, InputNumber, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { handleAllowNumbersOnly } from "../../../helpers/helpfulFunctions";
import { acceptRejectAuctionRequest, getUserBalanceInfoApi } from "../../../network";
import { toastError, toastSuccess } from "../../../helpers/toasters";

const AcceptOrRejectModal = ({
    open,
    mode,
    onCancel,
    selectedAuction,
    onSubmit
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submittable, setSubmittable] = useState(false);
    const [loadingBalance, setLoadingBalance] = useState(true);
    const [balanceOfSelectedType, setBalanceOfSelectedType] = useState("");
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    const initialValues = {
        quantity: ""
    }

    const validateQuantity = (_, value) => {
        if (!value && value !== 0) {
            return Promise.reject(t("auctionRequests.requiredQuantityMsg"))
        } else if (isNaN(value)) {
            return Promise.reject(t("auctionRequests.invalidQuantityMsg"))
        } else if (value <= 0) {
            return Promise.reject(t("auctionRequests.quantityLessThanZeroMsg"))
        } else if (!balanceOfSelectedType) {
            return Promise.reject(`${t("auctionRequests.noBalanceMsg")}`)
        } else if (selectedAuction && value > selectedAuction.maximumAuctionAmountForUserInKilo) {
            return Promise.reject(`${t("auctionRequests.maxQuantityPerUserMsg")} ${selectedAuction.maximumAuctionAmountForUserInKilo}`)
        } else if (balanceOfSelectedType && value > balanceOfSelectedType) {
            return Promise.reject(`${t("auctionRequests.insufficientBalanceMsg")}`)
        } else {
            return Promise.resolve()
        }
    }

    const handleFinish = (values) => {
        if (isSubmitting) return
        setIsSubmitting(true);

        const payload = {
            auctionId: selectedAuction.auctionId,
            isAccepted: mode === "accept"
        }

        if (mode === "accept") {
            payload.accpetedAmountInKilo = values.quantity
        }

        acceptRejectAuctionRequest(
            payload,
            (res) => {
                setIsSubmitting(false)
                if (res.success) {
                    onSubmit()
                    toastSuccess(res.message)
                } else {
                    toastError(res.message)
                }
            },
            (res) => {
                setIsSubmitting(false)
                toastError(res.message)
            }
        )
    }

    useEffect(() => {
        form
            .validateFields({
                validateOnly: true,
            })
            .then(
                (info) => {
                    setSubmittable(true);
                },
                (info) => {
                    if (info.errorFields.length === 0) {
                        setSubmittable(true);
                    } else {
                        setSubmittable(false);
                    }
                }
            );
    }, [values, form]);

    useEffect(() => {
        if (!selectedAuction) return;
        if (mode === "reject") {
            setLoadingBalance(false)
            return;
        }
        getUserBalanceInfoApi(
            (res) => {
                if (res.success) {
                    const selectedType = res.data.find((ele) => ele.categoryId === selectedAuction.typeId)
                    setLoadingBalance(false)
                    if (selectedType) {
                        setBalanceOfSelectedType(selectedType.totalQuantity);
                    }
                } else {
                    setLoadingBalance(false)
                    toastError(res.message);
                }
            },
            (res) => {
                setLoadingBalance(false)
                toastError(res.message);
            }
        );
    }, [selectedAuction])

    return (
        <Modal
            centered
            destroyOnClose={true}
            open={open}
            footer={false}
            closable={true}
            width={mode === "accept" ? (
                window.innerWidth >= 1440 ? "70%" : window.innerWidth >= 768 ? "70%" : "100%"
            ) : (
                window.innerWidth >= 1440 ? "50%" : window.innerWidth >= 992 ? "60%" : window.innerWidth >= 768 ? "70%" : "100%"
            )
            }
            className="auction-requests__accept-reject-modal"
            onCancel={onCancel}
        >
            {loadingBalance ? (
                <div className="d-flex justify-content-center align-items-center pt-5">
                    <Spin size="large" />
                </div>
            ) : (
                mode === "accept" ? (
                    <div dir={dir}>
                        <p className="text-center fsize-8 main-dark-text fw-bold">
                            {t("auctionRequests.enterQuantity")}
                        </p>
                        <Form
                            dir={dir}
                            form={form}
                            className="payment-details-form"
                            onFinish={handleFinish}
                            initialValues={initialValues}
                        >
                            <div dir={dir} className='auction-requests__accept-reject-modal__input-holder mx-auto'>
                                <p dir={dir} className='text--secondary-8 fw-bold fsize-9 mb-1'>{t("auctionRequests.quantity")}</p>
                                <Form.Item
                                    name="quantity"
                                    rules={[{ validator: validateQuantity }]}
                                    className='flex-fill'
                                >
                                    <InputNumber
                                        keyboard={false}
                                        controls={false}
                                        placeholder={"X"}
                                        addonAfter={<span className="fw-bold fsize-9 text--secondary-8">Kg</span>}
                                        className="auction-requests__accept-reject-modal__input-holder__input d-block w-100 fsize-6 fw-bold"
                                        onKeyDown={(e) => handleAllowNumbersOnly(e, true)}
                                    />
                                </Form.Item>
                                <div className="pt-4">
                                    <Button
                                        className="auction-requests__accept-reject-modal__confirm-btn w-100 fsize-9 fw-bold h-auto mx-auto d-block text-white py-2"
                                        htmlType="submit"
                                        disabled={!submittable || isSubmitting}
                                        loading={isSubmitting}
                                    >
                                        {t("auctionRequests.confirm")}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                ) : (
                    <div dir={dir}>
                        <Form
                            dir={dir}
                            form={form}
                            onFinish={handleFinish}
                            initialValues={initialValues}
                        >
                            <p className="text-center fw-bold fsize-9 pt-1 mb-4 mb-md-5">{t("auctionRequests.confirmReject")}</p>
                            <div dir={dir} className='auction-requests__accept-reject-modal__input-holder mx-auto'>
                                <Button
                                    className="auction-requests__accept-reject-modal__confirm-btn w-100 fsize-9 fw-bold h-auto mx-auto d-block text-white py-2"
                                    htmlType="submit"
                                    disabled={!submittable || isSubmitting || (!balanceOfSelectedType && mode === "accept")}
                                    loading={isSubmitting}
                                >
                                    {t("auctionRequests.confirm")}
                                </Button>
                            </div>
                        </Form>
                    </div>
                )
            )}
        </Modal>
    );
}

export default AcceptOrRejectModal;