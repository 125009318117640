export const en = {
  lang: "عربي",
  auth: {
    login: "Log In",
    signup: "Sign Up",
    phone: "Phone Number",
    name: "User Name",
    email: "Email",
    accept: "Accept",
    rememberMe: "Remember Me",
    noAccount: "Don't have an account?",
    withAccount: "Already have an account?",
    termsAndConditions: "Terms & Conditions",
    requiredName: "Name is required",
    invalidName: "Please, enter a valid full name",
    requiredMobile: "Phone Number is required",
    invalidMobile: "Please, enter a valid phone number",
    requiredEmail: "Email is required",
    invalidEmail: "Please, Enter a valid email",
    verifyOTP: "Verfiy OTP",
    resendCode: "Resend Code",
  },
  nav: {
    home: "Home",
    about: "About",
    pendingOrders: "Pending Orders",
    balance: "Balance",
    buyers: "Buyers",
    signout: "Log Out",
    contactUs: "Contact Us",
    terms: "Our Terms",
    profile: "Profile",
    bidders: "Bidders",
    luckyHours: "Discounted Prices",
    myGroups: "My Groups",
    store: "Store",
    more: "More",
    auctionRequests: "Auction Requests",
    buyAndSupply: "Buy & Supply",
  },
  home: {
    greenMatter: "Green Matter",
    description: "Your Partner in Sustainable Aluminum Recycling",
    buyItem: "Buy this item",
    exchangeItem: "Exchange Item",
    available: "Available in store",
    pricePerKilo: "EGP/Kilo",
    purchaseRequest: "Purchase Request",
    emptyItems:
      "All aluminum scraps have been sold out. Please Check later for added items",
    itemModal: {
      available: "Available",
      kilo: "Kilo",
      kilos: "Kilos",
      type: "Select order type",
      enterQuantity: "Enter quantity",
      enterUnit: "Enter unit",
      totalQuantity: "Total Quantity",
      totalPrice: "Total price",
      payment: "Select payment type",
      quantity: "Quantity",
      unit: "Unit",
      priceUnit: "EGP",
      requiredType: "Please, select order type",
      requiredQuantity: "Please, enter quantity",
      invalidQuantity: "Please, enter a valid quantity",
      requiredPayment: "Please, select payment type",
      quantityError: "Quantity not available in store",
      taxes: "2.5% fees will be added",
      walletNumber: "Wallet Number",
      requiredWallet: "Please, Enter the wallet phone number",
      invalidWallet: "Please, Enter a valid wallet phone number",
      productPriceChanged: "Unfortunately, the product price has just changed to",
      pressOk: "Press OK to continue",
      ok: "OK"
    },
    exchangeModal: {
      heading: "Exchange Item",
      selectOrderType: "Select order type",
      selectBalance: "Select from your balance",
      totalWeight: "Total weight",
      totalPrice: "Total price",
      enterTotalWeight: "Enter Total Weight",
      purchaseAmount: "Your purchase amount will be",
      purchasePrice: "Your purchase price",
      deduct: "Deduct from balance",
      invalidQuantity: "Enter a valid quantity",
      invalidQuantityNotEnoughBalance: "Your balance is not enough",
      weight: "Weight",
      price: "Price",
      exchange: "Exchange",
      exchangeItem: "Exchange item",
      noBalance: "You have no valid balance for exchange!",
      quantityLessThan1: "Minimum purchase amount is",
      notAvailableInStore: "There is no available stock for the quantity to be exchanged",
      kg: "Kg",
      le: "LE",
      totalPriceAfterCommissionDeduction: "Total Price After Commission Deduction"
    },
  },
  about: {
    about: "About",
    greenMatter: "Green Matter",
    firstSection: {
      1: "Welcome to Green Matter, the premier platform where individuals and businesses can enter to buy, sell and store aluminum scrap and cans based on commission.",
      2: "Since its establishment in 2012, Green Matter has evolved into the leading digital platform that facilitates the trade of aluminum scrap and cans between individuals, businesses and investors. We believe that by facilitating these exchanges, we can play a pivotal role in promoting a circular economy and reducing the environmental footprint associated with aluminum production in Egypt",
    },
    secondSection: {
      1: "We are committed to providing a seamless and efficient experience for all our users. Whether you are a supplier looking to offload your aluminum scrap or a buyer seeking high-quality materials, our platform offers a user-friendly interface that streamlines the entire process. Our advanced search and filtering options allow you to find exactly what you need, while our secure payment system guarantees a seamless and trustworthy transaction.",
      2: "At Green Matter, our efforts extend beyond reducing the environmental impact of aluminum production; we are dedicated to shaping a brighter future for all our stakeholders",
    },
    joinSection: {
      title: "Our Mission:",
      description:
        "Green Matter aims to transform the aluminum scrap industry with a community of like-minded individuals who are dedicated to making sustainable choices and driving positive transformations in the environment, economy and society.",
    },
    videoSection: {
      title: "Our Vision:",
      description:
        "To lead the circular economy in the MENA region's aluminum scrap industry, reducing waste, promoting sustainability, and encouraging active participation in environmental conservation and economic growth.",
    },
  },
  balance: {
    myBalance: "My Balance",
    quantity: "Quantity:",
    noData: "You don't have orders",
    totalQuantity: "Total Quantity",
    totalPrice: "Total Price",
    price: "Purchase Price:",
    date: "Purchase Date:",
    kg: "kg",
    totalOfferPrice: "Total Offer Price",
    totalProfit: "Total Profit",
    totalPurchasePrice: "Total Purchase Price",
    egp: "EGP",
    profitPercentage: "Profit Percentage"
  },
  buyers: {
    title: "My Offers",
    quantity: "Quantity:",
    price: "Price",
    requestDate: "Request Date:",
    noData: "You don't have offers yet",
    weight: "Total Weight:",
    moreOffers: "See other offers for",
  },
  pendingOrders: {
    title: "Pending Orders",
    weight: "Weight:",
    noData: "You don't have pending orders",
    paymentMethod: "Payment Method:",
    pending: "Pending",
    allOrders: "All orders",
    cash: "Cash",
    credit: "Credit Card",
    kg: "kg",
    purchasePrice: "Purchase Price:",
    totalPrice: "Total Price:",
    egp: "EGP"
  },
  notificationModal: {
    enterQuantity: "Enter Quantity",
    requiredQuantity: "Please, Enter quantity",
    correctQuantity: "Please, Enter correct quantity",
    accept: "Accept",
    reject: "Reject",
    rejectMsg: "You are about to reject this offer!",
    acceptOffer: "Accept Offer",
    rejectOffer: "Reject Offer",
    profit: "Profit value",
    profitPercentage: "Profit percentage",
    commission: "Commission value",
    commissionPercentage: "Commission percentage",
    egp: "EGP",
    priceAfterCommissionDeduction: "Price after commission deduction",
    sellItem: "Sell Item",
    purchasePrice: "Purchase Price",
    chooseCollectionType: "Choose if you want to collect Total price after commission deduction value either Cash or Store?"
  },
  exchangeModal: {
    notAvailableInStore:
      "There is no stock available for the quantity to be exchanged",
    yourPurchaseAmountWillBe: "Your purchase amount will be",
    purchasePrice: "Your purchase price",
  },
  profileModal: {
    noGroups: "No Groups Joined",
    myGroups: "My Groups"
  },
  bidders: {
    actualBudget: "Actual Budget",
    availableBudget: "Available Budget",
    empty: "Page is empty!",
    checkLater: "Please check later for added auctions!",
    activeAuctions: "Active Auctions",
    allAuctions: "All Auctions",
    minBidding: "Min. bidding",
    start: "Start:",
    started: "Started:",
    am: "am",
    pm: "pm",
    lastPrice: "Last Price",
    auctionPrice: "Auction Price",
    endTime: "Will end after",
    viewDetails: "View Details",
    join: "Join",
    deniedAccess: "You should to be a member of a group to view or join to our auctions!",
    ok: "Ok",
    backToAuctions: "Back To Auctions",
    liveAuctions: "Live Auctions",
    upcomingAuctions: "Coming Soon Auctions",
    oldAuctions: "My Old Auctions",
    searchPlaceholder: "Search by auction name or start date..",
    noResults: "No results found!",
    auctionDetails: "Auction Details",
    incrementValue: "Increment Value",
    allAuctionItems: "All Auction Items:",
    itemQuantity: "Item Quantity:",
    itemWeight: "Item weight:",
    seeAuctionGroups: "See auction groups",
    emptyChatMsg: "Be the first one to win the auction!",
    writeHere: "Write here",
    allGroups: "All Groups",
    selectGroupToJoinAuction: "Select one of your groups to join this auction!",
    groupName: "Group Name",
    joinAuctionRequiredGroupMsg: "You should to select the group that you need to join with it to win this auction",
    supervisorJoinedSameAuctionWithDifferentGroup: "Sorry, You are joining this auction with another group",
    chat: {
      you: "You",
      emptyState: "Please, Enter the auction price",
      invalidPrice: "Please, Enter a valid auction price",
      insufficientBudget: "Your group's budget is no longer sufficient to continue. You can increase it after members' approval",
      congratulations: "Congratulations!",
      winnerMsg: "Great work! You are the winner of the auction.",
      winnerOfAuction: "is the winner of the auction",
      reachedMarketPrice: "The auction price has reached the market price"
    },
    maxQuantityPerUser: "Max. quantity per user",
    participate: "Participate"
  },
  luckyHours: {
    luckyHours: "Discounted Prices",
    noOffers: "There are no available offers at this time, Please check again later",
    luckyHoursItems: "Discounted Prices Items",
    offer: "Offer"
  },
  storeItems: {
    description: "Description",
    enterQuantity: "Enter Quantity",
    buy: "Buy",
    requiredQuantityMsg: "Please, Enter the quantity",
    notAvailableQuantityMsg: "Quantity is not available in store",
    minQuantityMsg: "The minimum value is 1",
    totalPrice: "Total price",
    emptyState: "There are currently no items available in the store. Please check back later!",
    confirmMsg: {
      1: "You are about to pay",
      pay: "and you have to pay",
      recieve: "and you will receive",
      3: "cash on delivery"
    },
    confirm: "Confirm",
    backToHome: "Back To Home",
    exchange: "Exchange",
    selectPaymentMethod: "Select Payment Method",
    paymentMethod: "Payment Method",
    feesAdded: "*2.5% fees added",
    purchaseAmount: "Purchase Amount",
    totalPurchasePrice: "Total Purchase Price",
    item: "Item",
    exchangePriceDiffMsg: {
      recieve: "You will recieve",
      pay: "You have to pay",
      2: "the difference value which is",
      3: "cash on delivery"
    }
  },
  groups: {
    groupBudget: "Group Budget",
    groupMembers: "Group members",
    you: "You",
    supervisor: "Supervisor",
    increaseBudgetBtn: "Increase Budget",
    increaseBudget: {
      requiredIncrement: "Please, Enter the increment value for every group member",
      invalidIncrement: "Please, Enter a valid increment value",
      increaseBudgetFor: "Increase Budget for",
      incrementValuePerMember: "Increment value/member",
      sendIncrementRequest: "Send Increment Request",
      increaseBudgetPlaceholder: "Enter increment value"
    },
    lastRequestState: "Last Request State",
    Pending: "Pending",
    Accepted: "Accepted",
    Rejected: "Rejected",
    requestForMember: {
      requestedIncrementAmount: "Requested increment amount",
      confirmAccept: "Are you sure you want to accept this request?",
      confirmReject: "Are you sure you want to reject this request?",
      accept: "Accept",
      reject: "Reject",
      cancel: "Cancel",
      egp: "EGP"
    }
  },
  auctionRequests: {
    drawerTitle: "Auction Requests",
    noData: "You don’t have requests yet!",
    maxQuantityPerUser: "Max. quantity per user",
    kg: "kg",
    accept: "Accept",
    reject: "Reject",
    enterQuantity: "Enter quantity you want to use in the auction!",
    confirm: "Confirm",
    quantity: "Quantity",
    requiredQuantityMsg: "Please, Enter the quantity",
    invalidQuantityMsg: "Please, Enter a valid quantity",
    quantityLessThanZeroMsg: "Minimum value should be greater than 0",
    insufficientBalanceMsg: "Your balance from this type is not enough",
    maxQuantityPerUserMsg: "The maximum quantity should be less than or equal to",
    selectPaymentMethod: "Select Payment Method",
    paymentMethod: "Payment Method",
    auctionPrice: "Auction Price",
    auctionQuantity: "Auction Quantity",
    pay: "Pay",
    requiredPaymentMethod: "Please, select payment method",
    confirmReject: "Are you sure you want to reject this request?",
    noBalanceMsg: "Sorry you have no balance from this type. You can join its auction to get balance from it",
    request: "Request",
    myAuctions: "My Auctions",
    auctionStatus: "Auction status",
    participatedAmount: "Participated amount"
  },
  buyAndSupply: {
    title: "Buy And Supply",
    buy: "Buy",
    supply: "Supply",
    pageIsEmpty: "Page is empty!",
    checkItemsLater: "Please check later for added items",
    checkTypesLater: "Please check later for types",
    checkBalanceLater: "Please check your balance later",
    availableInStore: "Available in Store",
    lePerKg: "LE / KG",
    note: "note in english",
    buyItem: "Buy Item",
    enterQuantityMsg: "Enter quantity you want to buy",
    quantity: "Quantity",
    paymentMethod: "Payment Method",
    requiredPaymentMethod: "Please select a payment method",
    balance: "Balance",
    yourBalanceFrom: "Your Balance from",
    kilo: "Kilo",
    egp: "EGP",
    totalPrice: "Total Price",
    requiredQuantityMsg: "Please, enter the quantity",
    invalidQuantityMsg: "Please, enter a valid amount",
    exceedingAvailableQuantityMsg: "Please, enter a valid quantity",
    wantedQuantity: "Wanted Quantity",
    durationToRecieve: "Enter the quantity and select duration of receiving your money",
    selectDuration: "Select duration of receiving money",
    next: "Next",
    requiredDuration: "Please, select duration",
    kg: "Kg",
    after: "After",
    day: "day",
    days: "days",
    totalPriceBuy: "Total Price",
    price: "Price",
    pendingOrders: "Pending Orders",
    emptyPendingOrders: "You don’t have pending orders!"
  },
  invoice: {
    invoice: "Invoice",
    itemName: "Item Name",
    itemType: "Item Type",
    paymentMethod: "Payment Method",
    paymobFees: "Paymob Fees",
    quantity: "Quantity",
    unitPrice: "Unit Price",
    totalPrice: "Total Price",
    back: "Back",
    proceedToCheckout: "Proceed To Checkout",
    buyItem: "Buy Item"
  }
};
