import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/_routes";
import success from "../../assets/icons/payment-checked.svg";
import greenMatterLogo from "../../assets/icons/GreenMatter1234.svg";
import fail from "../../assets/icons/payment-cross.svg";
// import { doc, increment, updateDoc } from "firebase/firestore";
// import db from "../../firebase";
// import { pay } from "../../store/authorization";

function PostPay() {
  // const [updateUserBalance, setUpdateUserBalance] = useState(false);
  const location = useLocation();
  const isSuccess =
    location.search.split("success=")[1]?.split("&")[0] === "true"
      ? true
      : false;
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate(ROUTES.GLOBAL);
    }, 5000);
  }, []);

  // useEffect(() => {
  //   if (location.search.split("success=")[1]?.split("&")[0] === "true") {
  //     updateDoc(doc(db, `admin`, "notifications"), {
  //       unReadNotificationsCount: increment(1),
  //     });
  //   }
  // }, [location])

  return (
    <div className="d-flex flex-column justify-content-center" style={{ height: "100vh" }}>
      <div>
        <div className="text-center">
          <h1 className="my-2" style={{ color: "#0db14b" }}>
            Green Matter
          </h1>
          <figure
            className=""
            style={{ width: "170px", height: "170px", marginInline: "auto", objectFit: "cover" }}
          >
            <img
              src={greenMatterLogo}
              alt="greenMatterLogo"
              className="my-2"
              style={{ width: "100%", height: "100%" }}
            />
          </figure>
        </div>
        <div className="d-flex justify-content-center align-items-center flex-column flex-1">
          {isSuccess ? (
            <div className="text-center">
              <img src={success} alt="success" />
              <div className="my-4 f-25 fw-600 blue-text">
                {"Payment Approved"}
              </div>
              <div className="f-22 white-text fw-600 my-2">{"Thanks"}</div>
            </div>
          ) : (
            <div className="text-center">
              <img src={fail} alt="fail" />
              <div className="my-4 f-25 fw-600 blue-text">
                {"Something Went Wrong"}
              </div>
              <div className="f-22 white-text fw-600">
                {"please try again"}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PostPay;
