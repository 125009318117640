import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../../../services";

const SupplyCard = ({ data, onSupply }) => {
    const { t } = useTranslation();

    return (
        <article className="buy-and-supply__card buy-and-supply__card--supply">
            <img src={baseUrl + data?.typeImage} alt="" className="d-block img-fluid" />
            <div className="buy-and-supply__card__data-holder">
                <p className="fsize-5 fw-bold green-text mb-4">{data?.typeName}</p>
                <div className="buy-and-supply__card__boxes-holder buy-and-supply__card--supply__boxes-holder d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <span className="buy-and-supply__card__boxes-holder__value fw-700 fsize-6 text-truncate text-white">{`${data?.pricePerKilo} ${t("buyAndSupply.lePerKg")}`}</span>
                        <span className="text--secondary-8 fw-700 fsize-12 text-center text-white">{`${t("buyAndSupply.after")} ${data?.days} ${data?.days===1||data?.days>10?t("buyAndSupply.day"):t("buyAndSupply.days")}`}</span>
                    </div>
                    {/* <div className="d-flex flex-column justify-content-center align-items-center">
                        <span className="buy-and-supply__card__boxes-holder__value fw-700 fsize-6 text-truncate text-white">{data?.pricePerKilo}</span>
                        <span className="text--secondary-8 fw-700 fsize-12 text-center text-white">{t("buyAndSupply.lePerKg")}</span>
                    </div> */}
                    {/* <div className="d-flex flex-column justify-content-center align-items-center">
                        <span className="buy-and-supply__card__boxes-holder__value fw-700 fsize-6 text-truncate text-white">{data?.quantityPerKilo} {t("buyAndSupply.kilo")}</span>
                        <span className="text--secondary-8 fw-700 fsize-12 text-center text-white">{t("buyAndSupply.wantedQuantity")}</span>
                    </div> */}
                </div>
            </div>
            <Button
                htmlType="button"
                onClick={() => {
                    // if (!data.disableSupply) {
                        onSupply(data)
                    // }
                }}
                // disabled={data.disableSupply}
                className="buy-and-supply__card__buy-btn d-block h-auto w-100 border-0 cairo-family fw-bold fsize-6 text-white"
            >
                {t("buyAndSupply.supply")}
            </Button>
        </article>
    );
}

export default SupplyCard;