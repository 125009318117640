const SupplyIcon = () => {
    return (
        <svg className="recycle-nav__nav-icon" width="22" height="41" viewBox="0 0 22 41" fill="white" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2707_48649)">
                <path d="M-8.99675 1.5L-15.2467 9H-11.4967V15.25H-6.49674V9H-2.74675L-8.99675 1.5ZM7.87826 1.5C4.75326 1.5 4.75326 1.5 4.75326 4.6207V17.125C4.75326 20.25 4.75326 20.25 7.87826 20.25H14.1283C17.2533 20.25 17.2533 20.25 17.2533 17.125V4.625C17.2533 1.5 17.2533 1.5 14.1283 1.5H7.87826ZM-5.23925 17.5081C-5.65175 17.5175 -6.03706 17.697 -6.56268 18.2578L-7.07534 18.8023L-7.6076 18.2773C-8.18471 17.7085 -8.6401 17.5515 -9.10175 17.5718C-9.56331 17.5921 -10.106 17.8347 -10.7131 18.326L-11.1989 18.7191L-11.6603 18.2991C-12.3557 17.6701 -12.9482 17.4837 -13.5158 17.513C-13.9289 17.5345 -14.3576 17.6901 -14.7853 17.9623L-11.5846 23.7191C-10.3773 24.4575 -7.91018 24.7354 -6.21354 23.8804L-3.12026 18.1502C-3.70698 17.911 -4.23432 17.6659 -4.6901 17.5718C-4.8551 17.5377 -5.00831 17.5126 -5.15651 17.5081C-5.18411 17.5074 -5.21165 17.5074 -5.23925 17.5081ZM8.50326 22.75V29H4.75326L11.0033 36.5L17.2533 29H13.5033V22.75H8.50326ZM-12.2682 25.023C-13.6772 26.0184 -14.8085 27.7354 -15.2467 30.25C-15.6714 32.6872 -15.0024 34.3331 -13.8185 35.4549C-12.6346 36.5767 -10.8574 37.1532 -9.05292 37.1298C-7.24823 37.1063 -5.44854 36.4805 -4.23846 35.3379C-3.02831 34.1952 -2.34385 32.5703 -2.73198 30.2451C-3.13331 27.8422 -4.16948 26.1711 -5.4812 25.1597C-7.70643 26.3359 -10.4885 26.0859 -12.2682 25.023Z" />
            </g>
            <defs>
                <clipPath id="clip0_2707_48649">
                    <rect width="22" height="40" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default SupplyIcon;