import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../../../services";

const BuyCard = ({ data, onBuy }) => {
    const { t } = useTranslation();

    return (
        <article className="buy-and-supply__card h-100 d-flex flex-column">
            <img src={baseUrl + data?.image} alt="" className="d-block img-fluid" />
            <div className="buy-and-supply__card__data-holder flex-fill d-flex flex-column justify-content-between">
                <div>
                    <p className="fsize-5 fw-bold green-text mb-0 mb-md-2">{data?.itemName}</p>
                    <p className="fw-500 fsize-11 main-dark-text mb-3 mb-md-4">{data?.categoryName}</p>
                </div>
                <div className="buy-and-supply__card__boxes-holder d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <span className="buy-and-supply__card__boxes-holder__value text--secondary-8 fw-700 fsize-6 text-truncate">{data?.pricePerKilo}</span>
                        <span className="text--secondary-8 fw-700 fsize-12 text-center">{t("buyAndSupply.lePerKg")}</span>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <span className="buy-and-supply__card__boxes-holder__value text--secondary-8 fw-700 fsize-6 text-truncate">{data?.quantityPerKilo}</span>
                        <span className="text--secondary-8 fw-700 fsize-12 text-center">{t("buyAndSupply.availableInStore")}</span>
                    </div>
                </div>
            </div>
            <Button
                htmlType="button"
                onClick={() => onBuy(data)}
                className="buy-and-supply__card__buy-btn d-block h-auto w-100 border-0 cairo-family fw-bold fsize-6 text-white"
            >
                {t("buyAndSupply.buyItem")}
            </Button>
        </article>
    );
}

export default BuyCard;