const ProfileIcon = () => {
    return (
        <svg className="recycle-nav__nav-icon" width="24" height="24" viewBox="0 0 41 40" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.5381 21.25C19.0547 21.25 17.6047 20.8101 16.3713 19.986C15.1379 19.1619 14.1767 17.9906 13.609 16.6201C13.0413 15.2497 12.8928 13.7417 13.1822 12.2868C13.4716 10.832 14.1859 9.4956 15.2348 8.4467C16.2837 7.39781 17.6201 6.6835 19.0749 6.39411C20.5298 6.10472 22.0378 6.25325 23.4082 6.82091C24.7787 7.38856 25.95 8.34986 26.7741 9.58323C27.5982 10.8166 28.0381 12.2666 28.0381 13.75C28.0381 15.7391 27.2479 17.6468 25.8414 19.0533C24.4349 20.4598 22.5272 21.25 20.5381 21.25ZM20.5381 8.75C19.5492 8.75 18.5825 9.04325 17.7602 9.59266C16.938 10.1421 16.2971 10.923 15.9187 11.8366C15.5403 12.7502 15.4412 13.7555 15.6342 14.7255C15.8271 15.6954 16.3033 16.5863 17.0026 17.2855C17.7018 17.9848 18.5927 18.461 19.5626 18.6539C20.5325 18.8469 21.5379 18.7478 22.4515 18.3694C23.3651 17.991 24.146 17.3501 24.6954 16.5279C25.2448 15.7056 25.5381 14.7389 25.5381 13.75C25.5381 12.4239 25.0113 11.1522 24.0736 10.2145C23.1359 9.27679 21.8642 8.75 20.5381 8.75Z" />
            <path d="M20.5379 38.75C17.7659 38.7481 15.0289 38.1317 12.5238 36.9451C10.0187 35.7584 7.80781 34.0311 6.05039 31.8875L5.40039 31.0875L6.05039 30.3C7.80929 28.1591 10.0208 26.4347 12.5258 25.2507C15.0309 24.0668 17.7672 23.4527 20.5379 23.4527C23.3086 23.4527 26.0449 24.0668 28.55 25.2507C31.055 26.4347 33.2665 28.1591 35.0254 30.3L35.6754 31.0875L35.0254 31.8875C33.268 34.0311 31.0571 35.7584 28.552 36.9451C26.0469 38.1317 23.3098 38.7481 20.5379 38.75ZM8.67539 31.1C10.1957 32.7275 12.0343 34.0251 14.0771 34.9123C16.1199 35.7995 18.3233 36.2573 20.5504 36.2573C22.7775 36.2573 24.9809 35.7995 27.0237 34.9123C29.0665 34.0251 30.9051 32.7275 32.4254 31.1C30.9051 29.4725 29.0665 28.1748 27.0237 27.2876C24.9809 26.4004 22.7775 25.9426 20.5504 25.9426C18.3233 25.9426 16.1199 26.4004 14.0771 27.2876C12.0343 28.1748 10.1957 29.4725 8.67539 31.1Z" />
            <path d="M20.5379 38.75C16.2788 38.7528 12.1457 37.3056 8.81877 34.6464C5.49183 31.9872 3.16935 28.2746 2.23358 24.1196C1.2978 19.9646 1.8045 15.6148 3.67027 11.7862C5.53605 7.95753 8.64971 4.87817 12.4988 3.05492C16.3479 1.23167 20.703 0.773181 24.8474 1.75492C28.9918 2.73666 32.6784 5.10012 35.3006 8.45631C37.9227 11.8125 39.3241 15.9614 39.2741 20.2202C39.2241 24.479 37.7257 28.5938 35.0254 31.8875C33.268 34.0311 31.0572 35.7585 28.552 36.9451C26.0469 38.1317 23.3099 38.7482 20.5379 38.75ZM20.5379 3.75C17.324 3.75 14.1822 4.70304 11.5099 6.48862C8.8376 8.27419 6.75479 10.8121 5.52487 13.7814C4.29494 16.7507 3.97314 20.018 4.60015 23.1702C5.22716 26.3224 6.77482 29.2179 9.04743 31.4905C11.32 33.7631 14.2155 35.3107 17.3677 35.9378C20.5199 36.5648 23.7872 36.243 26.7565 35.013C29.7258 33.7831 32.2637 31.7003 34.0493 29.028C35.8349 26.3557 36.7879 23.2139 36.7879 20C36.7879 15.6902 35.0759 11.557 32.0284 8.50951C28.9809 5.46205 24.8477 3.75 20.5379 3.75Z" />
            <path d="M7.0127 31.1C7.0127 31.1 19.6002 45.1625 32.4127 32.5L34.0627 31.1C34.0627 31.1 23.3627 20 12.5002 26.6625L7.0127 31.1Z" />
            <path d="M20.5381 20C23.9899 20 26.7881 17.2018 26.7881 13.75C26.7881 10.2982 23.9899 7.5 20.5381 7.5C17.0863 7.5 14.2881 10.2982 14.2881 13.75C14.2881 17.2018 17.0863 20 20.5381 20Z" />
        </svg>
    );
}

export default ProfileIcon;