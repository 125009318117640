import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import { doc, setDoc } from "firebase/firestore";
import { doc, setDoc, getDoc } from 'firebase/firestore';

import { Button, Spin } from "antd";
import { ROUTES } from "../../../constants/_routes";
import { toastError, toastSuccess } from "../../../helpers/toasters";
import { confirmCodeApi, signInApi } from "../../../network";
import { login, updateFBToken } from "../../../store/authorization";
import db from "../../../firebase";

const OTPVerificationForm = ({ data, onCancel, keepSignedIn }) => {
  const [loading, setLoading] = useState(false);
  const [otp, setOTP] = useState(Array(6).fill(""));
  const [error, setError] = useState(false);
  const [counter, setCounter] = useState(60);
  const [showOverlaySpinner, setShowOverlaySpinner] = useState(false);
  const inputRefs = useRef(Array(6));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { fbToken } = useSelector((state) => state.auth);
  let timerId;

  const handleOTPChange = (event, index) => {
    const inputValue = event.target.value;
    if (!/^\d$/.test(inputValue)) {
      // Only allow single digits
      if (inputValue !== "") {
        return;
      }
    }
    const updatedOTP = [...otp];
    updatedOTP[index] = inputValue;
    if (inputValue !== "") {
      // Focus on the next input field
      if (index < 5) {
        inputRefs.current[index + 1].focus();
        inputRefs.current[index + 1].select();
      } else {
        inputRefs.current[index].blur();
      }
    }
    setOTP(updatedOTP);
    setError(updatedOTP.length !== 6);
  };

  const handleKeyPress = (e, indx) => {
    if (e.keyCode === 0 || e.keyCode == 229 || /[.+-]+/.test(e.key)) {
      e.preventDefault();
      return;
    }
    const updatedOTP = [...otp];
    // if user deleted an empty input, go to previous cell
    if ((e.code === "Backspace" || e.key === "Backspace") && updatedOTP[indx] === "") {
      if (indx > 0) {
        inputRefs.current[indx - 1].focus();
      }
    }
  }

  const sendOTP = () => {
    const enteredOTP = otp.join("");
    setOTP(Array(6).fill(""));
    if (enteredOTP.length !== 6 || !/^\d+$/.test(enteredOTP)) {
      setError(true);
      return;
    } else {
      setShowOverlaySpinner(true)
      data.verificationCode = enteredOTP;
      data.fireBaseToken = fbToken.fbToken;
      // setLoading(true);
      confirmCodeApi(
        data,
        (success) => {
          if (success?.success) {
            // localStorage.setItem("authData", JSON.stringify(success.data));
            setUserFBData(success.data?.userId);
            setLoading(false);
            // setUserId(success.data?.userId);
            toastSuccess(success.message);
            const authObj = {...success.data, keepSignedIn};
            dispatch(login(authObj));
            onCancel();
            navigate(ROUTES.GLOBAL, { replace: true });
            setShowOverlaySpinner(false)
          } else {
            inputRefs.current[0].focus();
            setLoading(false);
            setShowOverlaySpinner(false)
            toastError(success.message);
          }
        },
        (fail) => {
          inputRefs.current[0].focus();
          setLoading(false);
          setShowOverlaySpinner(false)
          toastError(fail.message);
        }
      );
      setError(false);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    // sendOTP();
  };

  const setUserFBData = (userId) => {
    const userDocRef = doc(db, 'users', userId.toString());
    const userData = {
      userId,
      unReadMessagesCount: 0,
    };
    // Check if the document already exists
    getDoc(userDocRef)
      .then((docSnapshot) => {
        if (!docSnapshot.exists()) {
          // Document does not exist, so set the data
          return setDoc(userDocRef, userData);
        } else {
          // Document already exists, handle accordingly (optional)
          return Promise.resolve(); // Resolve the promise to prevent unwanted then/catch chaining
        }
      })
  };

  const handleResendCode = () => {
    setLoading(true)
    if (counter > 0) return;
    // reset counter
    setCounter(60)
    timerId = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 1) clearInterval(timerId);
        return prevCounter - 1
      });
    }, 1000);
    signInApi(
      data,
      (success) => {
        if (success?.success) {
          setLoading(false);
          const authObj = {...success.data, keepSignedIn};
          dispatch(login(authObj));
        } else {
          setLoading(false);
          toastError(success.message);
        }
        setShowOverlaySpinner(false)
      },
      (fail) => {
        setShowOverlaySpinner(false);
        if (fail?.data) {
          setLoading(false);
          toastError(fail.data?.message);
        } else {
          setLoading(false);
          toastError();
        }
      }
    );
  }

  // send request for resending OTP after all inputs are filled by user
  useEffect(() => {
    if (otp.join("").length === 6) {
      sendOTP()
    }
  }, [otp])

  useEffect(() => {
    const fbToken = localStorage.getItem("fbToken");
    dispatch(updateFBToken({ fbToken }));
  }, []);

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  useEffect(() => {
    timerId = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 1) clearInterval(timerId);
        return prevCounter - 1
      });
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  return (
    <form onSubmit={handleSubmit} className="otp-form">
      <div className={`${counter > 0 ? "green-text" : "text-danger"} mb-4 fsize-9`}>{counter === 60 ? "01:00" : `00:${counter < 10 ? "0" : ""}${counter}`}</div>
      <div className="otp-inputs">
        {otp.map((digit, index) => (
          <input
            autoComplete="one-time-code"
            pattern='[0-9]'
            inputmode="numeric"
            className="otp-input fsize-9"
            key={index}
            type="number"
            maxLength={1}
            value={digit}
            onChange={(event) => handleOTPChange(event, index)}
            onKeyDown={(e) => handleKeyPress(e, index)}
            ref={(ref) => (inputRefs.current[index] = ref)}
          />
        ))}
      </div>
      {error && (
        <div
          className="text-align-start mrg-ys-5 wd-100"
          style={{ color: "red" }}
        >
          Please enter a valid 6-digit OTP.
        </div>
      )}
      <div className="flex-centering-col mt-5 wd-100 ">
        <Button
          loading={loading}
          className="contact-us__form-btn"
          disabled={counter > 0 ? true : false}
          onClick={handleResendCode}
        >
          {t("auth.resendCode")}
        </Button>
      </div>
      {
        showOverlaySpinner &&
        <Spin size="large" className="spin-overlay w-100 h-100 position-absolute top-0 start-0 d-flex justify-content-center align-items-center">
        </Spin>
      }
    </form>
  );
};

export default OTPVerificationForm;