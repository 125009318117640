import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { changeLang } from "../../../store/lang";

import LanguageIcon from "./LanguageIcon";

const LanguageBtn = ({ inNavbar = true, inNavMoreMenu }) => {
  const { lang } = useSelector((state) => state.language);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const handleLanguageChange = () => {
    if (lang === "en") {
      dispatch(changeLang({ lang: "ar", dir: "rtl" }));
      i18n.changeLanguage("ar")
    } else {
      dispatch(changeLang({ lang: "en", dir: "ltr" }));
      i18n.changeLanguage("en")
    }
  }

  return (
    <div
      role="button"
      onClick={handleLanguageChange}
      className={`language-btn d-flex ${inNavMoreMenu ? "" : inNavbar ? "flex-md-column gap-2" : "flex-column"} align-items-center gap-md-1 gap-lg-2`}
    >
      <LanguageIcon />
      <span className="recycle-nav__nav-titles fsize-9">{t('lang')}</span>
    </div>
  );
};

export default LanguageBtn;
