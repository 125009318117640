import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { acceptOfferByUserByOrderList, getStoreItems } from "../../network";
import { toastError, toastSuccess } from "../../helpers/toasters";

// assets
import arrowIcon from "../../assets/icons/angle-left.png"

// components
import Item from "../Items/Item";
import ExchangeModal from "../Items/ExchangeModal";
import ConfirmBuyModal from "./ConfirmBuyModal";
import PaymentMethodModal from "./PaymentMethodModal";

const StoreItems = () => {
    const [loading, setLoading] = useState(true);
    const [storeItems, setStoreItems] = useState(null);
    const [selectedItem, setSelectedItem] = useState("");
    const [submitting, setSubmitting] = useState("");
    const [confirmMsg, setConfirmMsg] = useState("");
    const [purchasedQuantity, setPurchasedQuantity] = useState("");
    const [purchasePrice, setPurchasePrice] = useState("");
    const [diffPrice, setDiffPrice] = useState("");
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [exchangeModalOpen, setExchangeModalOpen] = useState(false);
    const [redirectedFromOffers, setRedirectedFromOffers] = useState(false);
    const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
    const [renderCount, setRenderCount] = useState(1)
    const [renderMe, setRenderMe] = useState(false);
    const { dir } = useSelector((state) => state.language);
    const { state } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleExchangeModal = (item, quantity) => {
        setSelectedItem(item)
        setPurchasedQuantity(quantity)
        setExchangeModalOpen(true)
    }

    const handleCancelExchangeModal = (render) => {
        setSelectedItem(null);
        setExchangeModalOpen(false)
        if (render) {
            setRenderMe(!renderMe)
            setRenderCount(renderCount + 1)
        }
    }

    const handleConfirmModal = (msg, item, quantity) => {
        setConfirmMsg(msg)
        setConfirmModalOpen(true)
        setSelectedItem(item)
        setPurchasedQuantity(quantity)
    }

    const handleCancelConfirmModal = () => {
        setSelectedItem("")
        setPurchasedQuantity("")
        setConfirmModalOpen(false)
    }

    const handleCancelPaymentModal = () => {
        setSelectedItem("")
        setPurchasedQuantity("")
        setPaymentMethodModalOpen(false)
    }

    const handleBuy = (item, quantity) => {
        if (submitting) return;
        setSubmitting(true)
        setSelectedItem(item)

        const payload = { ...state.purchaseData }
        payload.storeItemId = item.itemId
        payload.quantity = quantity
        delete payload.priceAfterCommissionDeduction

        acceptOfferByUserByOrderList(
            payload,
            (res) => {
                setConfirmModalOpen(false)
                if (res.success) {
                    toastSuccess(res.message);
                    navigate("/", { replace: true })
                } else {
                    setLoading(false);
                    toastError(res.message);
                }
            },
            (res) => {
                setConfirmModalOpen(false)
                toastError(res.message);
                setLoading(false);
            }
        );
    }

    const handleBuyModal = (item, quantity, totalPrice) => {
        setPurchasePrice(totalPrice)
        if (redirectedFromOffers) {
            const diff = state.purchaseData.priceAfterCommissionDeduction - totalPrice;
            setDiffPrice(diff)
            if (totalPrice < state.purchaseData.priceAfterCommissionDeduction) {
                handleConfirmModal("orderPriceLessThanTotalPrice", item, quantity)
            } else if (totalPrice > state.purchaseData.priceAfterCommissionDeduction) {
                handleConfirmModal("orderPriceGreaterThanTotalPrice", item, quantity)
            } else {
                handleBuy(item, quantity)
            }
        } else {
            setSelectedItem(item)
            setPurchasedQuantity(quantity)
            setPaymentMethodModalOpen(true)
        }
    }

    useEffect(() => {
        if (state) {
            setRedirectedFromOffers(true)
        } else {
            setRedirectedFromOffers(false)
        }
        getStoreItems(
            (res) => {
                setLoading(false)
                if (res.success) {
                    setStoreItems(res.data)
                } else {
                    toastError(res.message)
                }
            }, (res) => {
                setLoading(false)
                toastError(res.message)
            }
        )
    }, [state, renderMe, dir])

    return (
        <section
            className="mct-section store-items p-4 p-lg-5 mct-section-signin justify-content-center"
        >
            {loading ? (
                <div className="h-100 d-flex justify-content-center align-items-center">
                    <Spin spinning size="large" />
                </div>
            ) : (
                <>
                    {redirectedFromOffers ? (
                        <Link
                            to={"/"}
                            dir={dir}
                            className={`store-items__home-link d-flex align-items-center gap-2 mb-4 text-white ${dir === "rtl" ? "ms-auto" : ""}`}
                        >
                            <img src={arrowIcon} alt="" className={`d-block img-fluid ${dir === "rtl" ? "rotate" : ""}`} />
                            <span className="fw-500 fsize-6">
                                {t("storeItems.backToHome")}
                            </span>
                        </Link>
                    ) : (
                        ""
                    )}
                    {storeItems?.length > 0 ? (
                        <div className="items d-flex flex-column">
                            {storeItems?.map((item) => (
                                <Item
                                    key={item.itemId + renderCount}
                                    item={item}
                                    storeItem={true}
                                    onSubmit={handleBuyModal}
                                    submitting={submitting}
                                    redirectedFromOffers={redirectedFromOffers}
                                    onExchangeItem={handleExchangeModal}
                                    loading={submitting && selectedItem?.itemId === item.itemId && !confirmMsg}
                                />
                            ))}
                        </div>
                    ) : (
                        <p className="text-center text-white fw-bold fsize-5 pt-5 mt-5">{t("storeItems.emptyState")}</p>
                    )}
                </>
            )}
            {confirmModalOpen && (
                <ConfirmBuyModal
                    open={confirmModalOpen}
                    onCancel={handleCancelConfirmModal}
                    purchasePrice={purchasePrice}
                    diffPrice={diffPrice}
                    onBuy={handleBuy}
                    submitting={submitting}
                    selectedItem={selectedItem}
                    purchasedQuantity={purchasedQuantity}
                />
            )}
            {exchangeModalOpen && (
                <ExchangeModal
                    isModalOpen={exchangeModalOpen}
                    onCancel={handleCancelExchangeModal}
                    selectedItem={selectedItem}
                    purchasedQuantity={purchasedQuantity}
                    itemType="storeItem"
                />
            )}
            {paymentMethodModalOpen && (
                <PaymentMethodModal
                    open={paymentMethodModalOpen}
                    onCancel={handleCancelPaymentModal}
                    selectedItem={selectedItem}
                    purchasedQuantity={purchasedQuantity}
                    onSubmit={() => {
                        setPaymentMethodModalOpen(false)
                        setRenderMe(!renderMe)
                        setRenderCount(renderCount + 1)
                    }}
                />
            )}
        </section>
    );
}

export default StoreItems;