import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import NavBar from "../Navbar/NavBar";
// import Footer from "../Footer/Footer";

const LayoutWithNav = () => {
    const { authorization } = useSelector((state) => state?.auth);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [showNav, setShowNav] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location?.pathname.includes("store") && location.state) {
            setShowNav(false)
        } else {
            setShowNav(true)
        }
    }, [location])

    useEffect(() => {
        if (!authorization.token) {
            navigate("/")
        } else {
            setIsAuthorized(true)
        }

    }, []);

    useEffect(() => {
        const onUnload = () => {
            if (!authorization.keepSignedIn) {
                localStorage.removeItem("persist:Recycling")
            }
        }
        window.addEventListener('beforeunload', onUnload);
        return () => window.removeEventListener('beforeunload', onUnload)
    }, []);

    return isAuthorized ?
        <>
            {showNav && (
                <NavBar />
            )}
            <Outlet />
            {/* <Footer /> */}
        </>
        : <div>
            <Spin size="large" className="spin-overlay w-100 h-100 position-absolute top-0 start-0 d-flex justify-content-center align-items-center">
            </Spin>
        </div>
}

export default LayoutWithNav;