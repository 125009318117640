// firebaseService.js
import { onSnapshot, doc, query, collection, orderBy } from "firebase/firestore";
import db from "./firebase";

const listenToUserCount = (userId, callback) => {
  const userDoc = doc(db, "users", userId);

  const unsubscribe = onSnapshot(userDoc, (doc) => {
    if (doc.exists()) {
      const count = doc.data().unReadMessagesCount;
      callback(count);
    }
  });
  return unsubscribe;
};

const listenToAuctionRequests = (userId, callback) => {
  const userDoc = doc(db, "users", userId);
  const unsubscribe = onSnapshot(userDoc, (doc) => {
    if (doc.exists()) {
      const count = doc.data().auctionNotifications;
      callback(count);
    }
  });
  return unsubscribe;
};

const listenToUserJoinedAuctions = (userId, callback) => {
  const userDoc = doc(db, "users", userId);
  const unsubscribe = onSnapshot(userDoc, (doc) => {
    if (doc.exists()) {
      const arr = doc.data().joinedAuctions;
      callback(arr);
    }
  });
  return unsubscribe;
};

const listenToAuctionChat = (auctionId, callback) => {
  const q = query(collection(db, 'chatMessages'), orderBy('timestamp'));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    const messages = []
    snapshot.docs.forEach((doc) => {
      if (doc.data().auctionId === auctionId) {
        const obj = { id: doc.id, ...doc.data() }
        messages.push(obj)
      }
    });
    callback(messages);
  });
  return unsubscribe;
};

const listenToAuctionUpdates = (auctionId, callback) => {
  const auctionDoc = doc(db, "auctions", auctionId);
  const unsubscribe = onSnapshot(auctionDoc, (doc) => {
    if (doc.exists()) {
      const lastPrice = doc.data().lastBid;
      const startAt = doc.data().startAt;
      callback(lastPrice, startAt);
    }
  });
  return unsubscribe;
};

const listenToLiveAuctions = (callback) => {
  const q = query(collection(db, 'auctions'));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    const liveAuctions = []
    snapshot.docs.forEach((doc) => {
      if (doc.data().active) {
        liveAuctions.push(doc.id)
      }
    });
    callback(liveAuctions.length);
  });
  return unsubscribe;
};

export {
  listenToUserCount,
  listenToAuctionChat,
  listenToAuctionUpdates,
  listenToUserJoinedAuctions,
  listenToAuctionRequests,
  listenToLiveAuctions
};