import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Drawer, Spin } from "antd";
import { getUserAuctionParticipations, getUserAuctionRequests } from "../../../network";
import { toastError } from "../../../helpers/toasters";

// assets
import closeBtnIcon from "../../../assets/icons/drawer-close.svg"

// components
import AuctionRequest from "./AuctionRequest";
import AcceptOrRejectModal from "./AcceptOrRejectModal";
import DrawerTabs from "./DrawerTabs";

const AuctionRequestsDrawer = ({
    onClose,
    open,
    dir,
}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [renderMe, setRenderMe] = useState(true);
    const [selectedAuction, setSelectedAuction] = useState(null);
    const [isAcceptRejectModalOpen, setIsAcceptRejectModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(1) // 1 --> requests, 2 --> participations
    const [mode, setMode] = useState("")
    const { t } = useTranslation();

    const handleRejectModal = (auctionId) => {
        setMode("reject")
        setSelectedAuction(auctionId)
        setIsAcceptRejectModalOpen(true)
    }

    const handleAcceptModal = (auctionId) => {
        setMode("accept")
        setSelectedAuction(auctionId)
        setIsAcceptRejectModalOpen(true)
    }

    const handleCancelAcceptRejectModal = () => {
        setMode("")
        setIsAcceptRejectModalOpen(false)
    }

    const handleSubmitRequest = () => {
        setRenderMe(!renderMe)
        setLoading(true)
        setSelectedAuction(null)
        handleCancelAcceptRejectModal()
    }

    useEffect(() => {
        if (activeTab === 1) {
            getUserAuctionRequests(
                (res) => {
                    setLoading(false)
                    if (res.success) {
                        setData(res.data);
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setLoading(false)
                    toastError(res.message);
                }
            );
        } else {
            getUserAuctionParticipations(
                (res) => {
                    setLoading(false)
                    if (res.success) {
                        setData(res.data);
                    } else {
                        toastError(res.message);
                    }
                },
                (res) => {
                    setLoading(false)
                    toastError(res.message);
                }
            );
        }
    }, [renderMe, activeTab])

    return (
        <>
            <Drawer
                title={<span className="fw-600 fsize-8 main-dark-text">{t("auctionRequests.drawerTitle")}</span>}
                style={{ textAlign: dir === "rtl" ? "end" : "start" }}
                placement={dir === "ltr" ? "right" : "left"}
                width={
                    window.innerWidth >= 1200 ?
                        "50%" : window.innerWidth >= 992 ?
                            "60%" : window.innerWidth >= 768 ?
                                "85%" : "100%"
                }
                closeIcon={<img src={closeBtnIcon} alt="" className="main-drawer__close-icon d-block img-fluid me-3" />}
                onClose={onClose}
                open={open}
                className={`main-drawer auction-requests-drawer ${dir === "rtl" ? "main-drawer--ar" : ""}`}
            >
                <DrawerTabs
                    active={activeTab}
                    onTabChange={(e) => {
                        setActiveTab(e.target.value)
                        setLoading(true)
                    }}
                />
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center pt-5">
                        <Spin size="large" />
                    </div>
                ) : data?.length > 0 ? (
                    <div className="auction-requests-drawer__requests-holder py-3 px-1 px-lg-3 d-flex flex-column">
                        {data.map((request) => (
                            <AuctionRequest
                                data={request}
                                key={request.auctionId}
                                onReject={() => handleRejectModal(request)}
                                onAccept={() => handleAcceptModal(request)}
                                mode={activeTab}
                            />
                        ))}
                    </div>
                ) : (
                    <p className="text-white fsize-8 text-center pt-5 fw-600 mb-0">
                        {t("auctionRequests.noData")}
                    </p>
                )}
            </Drawer>
            {isAcceptRejectModalOpen && (
                <AcceptOrRejectModal
                    open={isAcceptRejectModalOpen}
                    mode={mode}
                    onCancel={handleCancelAcceptRejectModal}
                    selectedAuction={selectedAuction}
                    onSubmit={handleSubmitRequest}
                />
            )}
        </>
    );
}

export default AuctionRequestsDrawer;