import { useState } from "react";
import { Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { pdfjs, Document, Page } from 'react-pdf';
import { baseUrl } from "../../../services";

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const TermAndConditionsModal = ({ isOpen, onCancel, data }) => {
    const { t } = useTranslation();
    const { dir } = useSelector((state) => state.language);
    const [loading, setLoading] = useState(true);

    function onDocumentLoadSuccess({ numPages }) {
        setLoading(false)
    }

    return (
        <Modal
            centered
            title={<h1 dir={dir} className="green-text text-center fsize-5 mb-0 mb-md-3">{t("auth.termsAndConditions")}</h1>}
            width={window.innerWidth >= 992 ? "65vw" : window.innerWidth >= 768 ? "85vw" : "95vw"}
            bodyStyle={{ overflowY: "auto", maxHeight: '68vh', minHeight: "200px", display: "flex", justifyContent: loading ? "center" : "", alignItems: loading ? "center" : "" }}
            open={isOpen}
            wrapClassName="terms-modal"
            rootClassName="terms-modal-root"
            onCancel={onCancel}
            closable={true}
            footer={[]}
        >
            <div className="mx-auto">
                <Document
                    file={baseUrl + data}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<Spin size="large" />}
                    noData={"No data..."}
                >
                    <Page
                        pageNumber={1}
                        loading=""
                        scale={window.innerWidth >= 768 ? 1 : window.innerWidth >= 500 ? 0.8 : 0.6}
                    />
                </Document>
            </div>
        </Modal>
    );
}

export default TermAndConditionsModal;