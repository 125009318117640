import { Helmet } from "react-helmet";

const WebsiteTitle = ({ title }) => {
  return (
    <Helmet>
      <title>{title?.length ? `Green Matter - ${title[0].toUpperCase()}${title.slice(1,)}` : "Green Matter"}</title>
    </Helmet>
  );
};

export default WebsiteTitle;
