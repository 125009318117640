const StoreIcon = () => {
    return (
        <svg className="recycle-nav__nav-icon" width="24" height="24" viewBox="0 0 40 40" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1526_11293)">
                <path d="M28.4185 21.5586H22.9833C21.4958 21.5586 20.2861 22.7682 20.2861 24.2558V28.4241C20.2861 29.9117 21.4958 31.1213 22.9833 31.1213H28.4185C29.9061 31.1213 31.1157 29.9117 31.1157 28.4241V24.2558C31.1157 22.7682 29.9061 21.5586 28.4185 21.5586ZM29.1541 28.4241C29.1541 28.8328 28.819 29.1597 28.4185 29.1597H22.9833C22.5747 29.1597 22.2477 28.8246 22.2477 28.4241V24.2558C22.2477 23.8471 22.5828 23.5202 22.9833 23.5202H28.4185C28.8272 23.5202 29.1541 23.8553 29.1541 24.2558V28.4241Z" />
                <path d="M40 13.9816C40 13.81 39.9591 13.6465 39.8692 13.4994L33.9191 3.03763C33.7474 2.73522 33.4205 2.53906 33.0691 2.53906H6.92276C6.57131 2.53906 6.24438 2.72705 6.07274 3.03763L0.130772 13.4994C0.0490396 13.6465 0 13.81 0 13.9816C0 16.3274 1.41398 18.338 3.43277 19.2289V36.4827C3.43277 37.0221 3.87413 37.4635 4.41357 37.4635H35.5783C36.1177 37.4635 36.5591 37.0221 36.5591 36.4827V19.3515C36.5591 19.3106 36.5591 19.2779 36.5509 19.2452C38.5779 18.3543 40 16.3355 40 13.9816ZM7.49489 4.50882H32.4969L37.1475 12.6903H2.85247L7.49489 4.50882ZM28.4675 14.6437C28.1569 16.4091 26.6122 17.7495 24.7568 17.7495C22.9015 17.7495 21.3568 16.4091 21.0462 14.6437H28.4675ZM18.962 14.6437C18.6514 16.4091 17.1067 17.7495 15.2513 17.7495C13.396 17.7495 11.8512 16.4091 11.5325 14.6437H18.962ZM2.02697 14.6437H9.45648C9.14589 16.4091 7.59297 17.7495 5.73764 17.7495C3.8823 17.7577 2.33756 16.4091 2.02697 14.6437ZM16.4773 35.5019H10.8378V24.9175C10.8378 24.1492 11.4589 23.5199 12.2354 23.5199H15.0879C15.8562 23.5199 16.4855 24.141 16.4855 24.9175V35.5019H16.4773ZM34.5975 35.5019H18.4389V24.9175C18.4389 23.0703 16.935 21.5583 15.0797 21.5583H12.2272C10.3801 21.5583 8.868 23.0622 8.868 24.9175V35.51H5.39436V19.7111C5.50879 19.7193 5.62321 19.7193 5.73764 19.7193C7.71557 19.7193 9.46465 18.714 10.4945 17.1856C11.5243 18.714 13.2734 19.7193 15.2513 19.7193C17.2293 19.7193 18.9702 18.714 20.0082 17.1856C21.038 18.714 22.7871 19.7193 24.7568 19.7193C26.7348 19.7193 28.4757 18.714 29.5055 17.1856C30.5353 18.714 32.2844 19.7193 34.2624 19.7193C34.3768 19.7193 34.483 19.7111 34.5975 19.7111V35.5019ZM34.2624 17.7577C32.407 17.7577 30.8623 16.4173 30.5517 14.6519H37.9812C37.6624 16.4091 36.1177 17.7577 34.2624 17.7577Z" />
            </g>
            <defs>
                <clipPath id="clip0_1526_11293">
                    <rect width="40" height="40" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default StoreIcon;