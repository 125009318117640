import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal, Radio, Spin } from "antd";
import { toastError, toastSuccess } from "../../../helpers/toasters";
import {
  getBalanceInfoForExchangesApi,
  getBalanceInfoForStoreItem,
  getOrderStatusApi,
  placeOrderForExchangeApi,
  placeOrderForStoreItem,
} from "../../../network";
import emptyBalance from "../../../assets/icons/exchange-wallet.png";
import Item from "./Item";
// import { doc, increment, updateDoc } from "firebase/firestore";
// import db from "../../../firebase";

const ExchangeModal = ({ isModalOpen, selectedItem, onCancel, itemType, purchasedQuantity }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [data, setData] = useState({
    balanceWeight: 0,
    balancePrice: 0,
    purchaseWeight: 0,
    orderTypeId: 2,
    payItemRequest: {
      itemId: selectedItem?.itemId,
      unitId: itemType === "storeItem" ? selectedItem?.price : selectedItem?.itemUnits[0].unitId,
      quantity: 0,
    },
    soldItems: [],
  });
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [purchaseAmountError, setPurchaseAmountError] = useState("");
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [totalItemPrice, setTotalItemPrice] = useState(0);
  const [diffPrice, setDiffPrice] = useState(0);
  const [priceAfterCommission, setPriceAfterCommission] = useState(0);
  const { dir } = useSelector((state) => state.language);
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const getOrderStatus = () => {
    getOrderStatusApi(
      (res) => {
        if (res.success) {
          setOrderStatus(res.data);
          const defaultStatusId = res.data.find(
            (ele) => ele.name === "Purchase And Store" || ele.name === "شراء وتخزين"
          ).id;
          setData((prev) => ({
            ...prev,
            orderTypeId: defaultStatusId,
          }));
        } else {
          toastError(res.message);
        }
      },
      (res) => {
        toastError(res?.message);
      }
    );
  };
  const getAllBalanceForExchanges = () => {
    const successCallback = (res) => {
      setLoadingData(false);
      if (res.success) {
        if (res.data.length > 0) {
          setShowForm(true);
        }
        res.data.map((item) =>
          setData((prevData) => ({
            ...prevData,
            soldItems: [
              ...prevData.soldItems,
              {
                totalQuantity: item?.quantity,
                perKiloPrice: +item?.perKiloPrice,
                itemId: item?.itemId,
                image: item?.image,
                item: item?.item,
                isSelected: false,
                inputValue: 0,
                gmCommissionPercentage: item.gmCommissionPercentage || 0
              },
            ],
          }))
        );
      } else {
        toastError(res.mesasge)
      }
    }
    const failCallback = (res) => {
      setLoadingData(false);
      toastError(res.message);
    }
    if (itemType === "storeItem") {
      getBalanceInfoForStoreItem(successCallback, failCallback)
    } else {
      getBalanceInfoForExchangesApi(
        selectedItem.itemId,
        successCallback,
        failCallback
      );
    }
  };
  const handleSelectOrderType = (e) => {
    const id = orderStatus.find((ele) => ele.name === e.target.value).id;
    setData((prevData) => ({ ...prevData, orderTypeId: id }));
  };
  const handleInputOnChange = (value, item) => {
    setData((prevData) => {
      const soldItems = prevData.soldItems.map((itm) => {
        if (item?.itemId === itm?.itemId) {
          return {
            ...item,
            inputValue: +value
          };
        } else {
          return itm;
        }
      });
      return {
        ...prevData,
        soldItems,
      };
    });
  };
  const calculateTotalWeight = () => {
    let soldWeight = 0;
    let soldPrice = 0;
    let invalid = false;
    data?.soldItems.forEach((item) => {
      soldWeight += item?.inputValue;
      soldPrice += item?.inputValue * item?.perKiloPrice;
      if (item.isSelected && (item.inputValue <= 0 || item.inputValue > item.totalQuantity || isNaN(item.inputValue))) {
        invalid = true;
      }
    });
    if (invalid) {
      setInvalidInput(true);
    } else {
      setInvalidInput(false);
    }
    setData((prev) => ({
      ...prev,
      balanceWeight: soldWeight,
      balancePrice: soldPrice,
    }));
  };
  const handleSelectItem = (event, itemId) => {
    setData((prevData) => ({
      ...prevData,
      soldItems: prevData.soldItems.map((item) =>
        item?.itemId === itemId
          ? {
            ...item,
            isSelected: event.target.checked,
            inputValue:
              event.target.checked === true ? item?.totalQuantity : 0,
          }
          : item
      ),
    }));
  };
  const handleOnFinish = () => {
    if (
      invalidInput ||
      isSubmitting ||
      (purchaseAmount > selectedItem?.quantityPerKilo
        ? true
        : false || purchaseAmount === 0)
    ) {
      return;
    }
    setIsSubmitting(true);
    const soldItems = structuredClone(data.soldItems)
      .filter((item) => item.isSelected)
      .map((item) => {
        item.totalQuantity = item.inputValue;
        return item;
      });
    const dataCopy = structuredClone(data);
    dataCopy.soldItems = soldItems;
    dataCopy.payItemRequest.quantity = purchaseAmount;
    delete dataCopy.purchaseWeight;
    delete dataCopy.balanceWeight;
    delete dataCopy.balancePrice;

    if (itemType === "storeItem") {
      const payload = {
        payItemRequest: {
          storeItemId: selectedItem.itemId,
          quantity: purchasedQuantity
        },
        paymentTypeId: 5,
        soldItems
      }

      placeOrderForStoreItem(
        payload,
        (res) => {
          setIsSubmitting(false);
          if (res.success) {
            toastSuccess(res.message);
            onCancel(true)
          } else {
            toastError(res.message);
          }
        },
        (res) => {
          setIsSubmitting(false);
          toastError(res.message);
        }
      )
    } else {
      placeOrderForExchangeApi(
        dataCopy,
        (res) => {
          setIsSubmitting(false);
          if (res.success) {
            // updateDoc(doc(db, `admin`, "notifications"), {
            //   unReadNotificationsCount: increment(1),
            // });
            toastSuccess(res.message);
          } else {
            toastError(res.message);
          }
          onCancel(true);
        },
        (res) => {
          setIsSubmitting(false);
          toastError(res.message);
        }
      );
    }
  };

  useEffect(() => {
    calculateTotalWeight();
    if (!data.soldItems.some((ele) => ele.isSelected)) {
      setPurchaseAmountError("")
    }
  }, [data.soldItems]);

  useEffect(() => {
    let itemPrice = itemType === "storeItem" ? selectedItem.price : +selectedItem.itemUnits[0].price;
    let balancePrice = data.balancePrice;
    let equivalentQuantity = balancePrice / itemPrice;
    let equivalentPrice = equivalentQuantity * itemPrice;
    // if (equivalentQuantity < 1 && data.soldItems.some((ele) => ele.isSelected)) {
    if (equivalentQuantity < selectedItem?.unitValue && data.soldItems.some((ele) => ele.isSelected)) {
      setPurchaseAmountError("quantityLessThan1")
    } else if (equivalentQuantity > selectedItem?.quantityPerKilo) {
      setPurchaseAmountError("notAvailableInStore")
    } else {
      setPurchaseAmountError("")
    }
    setPurchaseAmount(equivalentQuantity);
    setPurchasePrice(equivalentPrice);
    if (equivalentPrice && itemType === "storeItem") {
      const priceAfterCommissionDeduction = equivalentPrice - (equivalentPrice * data.soldItems[0].gmCommissionPercentage / 100)
      const totalDiffPrice = priceAfterCommissionDeduction - totalItemPrice;
      setDiffPrice(totalDiffPrice)
      setPriceAfterCommission(priceAfterCommissionDeduction)
    }
  }, [data.balanceWeight, data.balancePrice]);

  useEffect(() => {
    if (selectedItem) {
      getAllBalanceForExchanges();
      getOrderStatus();
    }
  }, [selectedItem, itemType]);

  useEffect(() => {
    if (itemType === "storeItem" && purchasedQuantity && selectedItem) {
      setTotalItemPrice(selectedItem.price * purchasedQuantity)
    }
  }, [itemType, purchasedQuantity, selectedItem])

  return (
    <Modal
      centered
      width={window.innerWidth >= 1440 ? "75%" : "90%"}
      destroyOnClose={true}
      maskClosable={false}
      open={isModalOpen}
      footer={false}
      closable={true}
      className="exchangeModal"
      onCancel={() => onCancel(false)}
    >
      <h2 className="green-text fsize-4 available-quantity mb-4 wd-100 text-center">
        {t("home.exchangeModal.heading")}
      </h2>
      <Spin size="large" className="w-100 pt-5 mt-5" spinning={loadingData}>
        {!loadingData ? (
          showForm ? (
            <Form
              size={"large"}
              dir={dir}
              form={form}
              className="payment-details-form"
              initialValues={{
                orderType: dir === "ltr" ? "Purchase And Store" : "شراء وتخزين",
              }}
              onFinish={handleOnFinish}
              layout="vertical"
            >
              {itemType !== "storeItem" ? (
                <>
                  <h3 className="green-text fsize-9 mb-2">
                    {t("home.exchangeModal.selectOrderType")}
                  </h3>
                  <Form.Item name="orderType">
                    <Radio.Group onChange={handleSelectOrderType}>
                      {orderStatus?.map((status) => (
                        <Radio
                          key={status.id}
                          value={status.name}
                          className="fsize-9 exchangeModal__label fw-semibold"
                        >
                          {status.name}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                </>
              ) : (
                ""
              )}
              <h3 className="green-text fsize-9 mb-3">
                {t("home.exchangeModal.selectBalance")}
              </h3>
              <div className="d-flex flex-column flex-md-row align-items-md-center gap-1 gap-md-5 mb-4">
                <div className="exchangeModal__label fw-semibold">
                  {t("home.exchangeModal.totalWeight")}: {data?.balanceWeight.toFixed(2)}{" "}
                  {t('home.exchangeModal.kg')}
                </div>
                <div className="exchangeModal__label fw-semibold">
                  {t("home.exchangeModal.totalPrice")}: {purchasePrice.toFixed(2)} {t('home.exchangeModal.le')}
                </div>
              </div>
              {itemType === "storeItem" ? (
                <div className="exchangeModal__label fw-semibold mb-4">
                  {t("home.exchangeModal.totalPriceAfterCommissionDeduction")}: {priceAfterCommission.toFixed(2)} {t('home.exchangeModal.le')}
                </div>
              ) : (
                ""
              )}
              <Form.Item name="items">
                <div className="exchangeModal__items-holder pe-3 d-flex flex-column gap-3">
                  {data?.soldItems?.length > 0 &&
                    data.soldItems.map((item) => (
                      <Item
                        checked={item.isSelected}
                        key={item.itemId}
                        item={item}
                        onSelect={handleSelectItem}
                        onInputChange={handleInputOnChange}
                      />
                    ))}
                </div>
              </Form.Item>
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-center gap-2 gap-md-3 mb-3">
                <p className="green-text fw-semibold text-center text-md-start fsize-8 mb-0">
                  {t(itemType === "storeItem" ? "storeItems.purchaseAmount" : "home.exchangeModal.purchaseAmount")}
                </p>
                <div className="green-text exchangeModal__total-result text-center py-1 fw-semibold fsize-8">
                  {itemType === "storeItem" ? purchasedQuantity : purchaseAmount.toFixed(2)} {t(itemType === "storeItem" ? "storeItems.item" : 'home.exchangeModal.kg')}
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-between align-items-center gap-2 gap-md-3 mb-4">
                <p className="green-text fw-semibold text-center text-md-start fsize-8 mb-0">
                  {t(itemType === "storeItem" ? "storeItems.totalPurchasePrice" : "home.exchangeModal.purchasePrice")}
                </p>
                <div className="green-text exchangeModal__total-result text-center py-1 fw-semibold fsize-8">
                  {itemType === "storeItem" ? totalItemPrice.toFixed(2) : purchasePrice.toFixed(2)} {t('home.exchangeModal.le')}
                </div>
              </div>
              {itemType === "storeItem" && diffPrice ? (
                <p className="text--secondary-8 fw-bold fsize-8 text-center mb-4">
                  {`${diffPrice > 0 ? t("storeItems.exchangePriceDiffMsg.recieve") : t("storeItems.exchangePriceDiffMsg.pay")} ${t("storeItems.exchangePriceDiffMsg.2")}`}
                  <br />
                  {`${Math.abs(diffPrice).toFixed(2)} ${t('home.exchangeModal.le')} ${t("storeItems.exchangePriceDiffMsg.3")}`}
                </p>
              ) : (
                ""
              )}
              <Button
                disabled={
                  purchaseAmountError ||
                    purchaseAmount === 0 ||
                    invalidInput ||
                    purchaseAmount > selectedItem?.quantityPerKilo ||
                    purchaseAmount < selectedItem?.unitValue
                    ? true
                    : false
                }
                loading={isSubmitting}
                className="items__buy-btn exchangeModal__submit-btn mx-auto d-block px-5 fsize-8 text-center"
                htmlType="submit"
              >
                {t("home.exchangeModal.exchange")}
              </Button>
              {purchaseAmountError ? (
                <p className="exchangeModal__error text-center text-danger mt-3 mb-0">
                  {t(`home.exchangeModal.${purchaseAmountError}`)}
                  {purchaseAmountError === "quantityLessThan1" ? (
                    <>{" "}{selectedItem?.unitValue} {t('home.exchangeModal.kg')}</>
                  ) : (
                    ""
                  )}
                  {/* {t(`home.exchangeModal.${purchaseAmountError}`)} 1 {t('home.exchangeModal.kg')} */}
                </p>
              ) : (
                ""
              )}
            </Form>
          ) : (
            <div className="py-5">
              <img
                src={emptyBalance}
                alt=""
                className="d-block mx-auto img-fluid mb-2"
              />
              <p className="main-dark-text fw-bold fsize-5 text-center">
                {t("home.exchangeModal.noBalance")}
              </p>
            </div>
          )
        ) : (
          ""
        )}
      </Spin>
    </Modal>
  );
};

export default ExchangeModal;
