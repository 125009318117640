const BalanceIcon = () => {
    return (
        <svg className="recycle-nav__nav-icon" width="24" height="24" viewBox="0 0 41 40" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_354_7924)">
                <path d="M37.6943 19.1595V14.4437C37.6943 12.1268 35.8508 10.2334 33.5537 10.1508V7.25617C33.5537 5.74843 32.3271 4.52179 30.8193 4.52179H23.2914L22.3193 1.81968C22.0714 1.13062 21.5696 0.579995 20.9065 0.269292C20.2434 -0.0414893 19.4992 -0.0746143 18.8111 0.175854L5.15059 5.14781C4.46324 5.39796 3.91488 5.90101 3.60645 6.56429C3.29801 7.22757 3.26668 7.97109 3.51832 8.65796L4.06715 10.156C1.82223 10.2948 0.0380859 12.1644 0.0380859 14.4437V35.6937C0.0380859 38.063 1.96566 39.9905 4.33496 39.9905H33.3975C35.7668 39.9905 37.6943 38.063 37.6943 35.6937V30.8216C39.0176 30.6314 40.0381 29.4906 40.0381 28.1155V21.8655C40.0381 20.4905 39.0176 19.3497 37.6943 19.1595ZM37.6943 28.1155C37.6943 28.3309 37.5191 28.5062 37.3037 28.5062H31.0537C29.1152 28.5062 27.5381 26.9291 27.5381 24.9905C27.5381 23.052 29.1152 21.4749 31.0537 21.4749H37.3037C37.5191 21.4749 37.6943 21.6502 37.6943 21.8655V28.1155ZM5.71902 7.85171C5.67051 7.71929 5.70605 7.60749 5.7316 7.55257C5.75715 7.49773 5.81973 7.39843 5.95223 7.35023L19.6127 2.37828C19.7454 2.33007 19.8571 2.36593 19.9121 2.39164C19.967 2.41734 20.0661 2.48031 20.1139 2.61312L20.8006 4.52187H20.2725C18.7647 4.52187 17.5381 5.74851 17.5381 7.25624V10.1469H6.55988L5.71902 7.85171ZM31.21 7.25617V10.1468H19.8818V7.25617C19.8818 7.04078 20.0571 6.86554 20.2725 6.86554H30.8193C31.0347 6.86554 31.21 7.04078 31.21 7.25617ZM33.3975 37.6468H4.33496C3.25801 37.6468 2.38184 36.7706 2.38184 35.6937V14.4437C2.38184 13.3667 3.25801 12.4905 4.33496 12.4905H33.3975C34.4744 12.4905 35.3506 13.3667 35.3506 14.4437V19.1312H31.0537C27.8229 19.1312 25.1943 21.7597 25.1943 24.9905C25.1943 28.2214 27.8229 30.8499 31.0537 30.8499H35.3506V35.6937C35.3506 36.7706 34.4744 37.6468 33.3975 37.6468Z"  />
                <path d="M31.0537 26.1624C31.7009 26.1624 32.2256 25.6377 32.2256 24.9905C32.2256 24.3433 31.7009 23.8187 31.0537 23.8187C30.4065 23.8187 29.8818 24.3433 29.8818 24.9905C29.8818 25.6377 30.4065 26.1624 31.0537 26.1624Z"  />
            </g>
            <defs>
                <clipPath id="clip0_354_7924">
                    <rect width="40" height="40"  transform="translate(0.0380859)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default BalanceIcon;