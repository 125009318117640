import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import store from "./store";
import persistStore from "redux-persist/es/persistStore";
import i18next from "./localization/i18next";
import "./assets/styles/Styles.scss";
import "./index.css";
// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./firebase";
import TransitionToTop from "./modules/Common/TransitionToTop";
// import { initializeApp } from "firebase/app";
// import { getMessaging } from "firebase/messaging";

const direction = "ltr";
const persistor = persistStore(store);
ConfigProvider.config({
  theme: {
    primaryColor: "#A7C0E8",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const Index = () => {
  // useEffect(() => {
  //   const firebaseConfig = {
  //     apiKey: "AIzaSyCW7tLO10TUngkWDxaZqyXRMPDwXIEhid4",
  //     authDomain: "recycling-33b80.firebaseapp.com",
  //     projectId: "recycling-33b80",
  //     storageBucket: "recycling-33b80.appspot.com",
  //     messagingSenderId: "638393445867",
  //     appId: "1:638393445867:web:547f6cb1fe36b55116bdae",
  //   };

  //   const app = initializeApp(firebaseConfig);
  //   const messaging = getMessaging(app);
  //   if ("serviceWorker" in navigator) {
  //     navigator.serviceWorker
  //       .register("/firebase-messaging-sw.js", { scope: "/" })
  //       .then((registration) => {
  //         // Service worker registered successfully
  //         console.log(
  //           "Service Worker registered with scope:",
  //           registration.scope
  //         );
  //       })
  //       .catch((error) => {
  //         // Service worker registration failed
  //         console.error("Service Worker registration failed:", error);
  //       });
  //   }
  // }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18next}>
          <ConfigProvider direction={direction}>
            <BrowserRouter>
              <TransitionToTop />
              <App />
            </BrowserRouter>
          </ConfigProvider>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
};

root.render(<Index />);
