import React, { useEffect, useState } from "react";
import NavBar from "../Navbar/NavBar";
import LandingPage from "../LandingPage/LandingPage";
import Footer from "../Footer/Footer";
import Items from "../Items/Items";
import { getUserBalanceApi } from "../../network";
import { toastError } from "../../helpers/toasters";

function Global() {
  // const [userBalance, setUserBalance] = useState();
  // useEffect(() => {
  //   getUserBalance();
  // }, []);

  // const getUserBalance = () => {
  //   getUserBalanceApi(
  //     (success) => {
  //       if (success?.success) {
  //         setUserBalance(success.data);
  //       } else {
  //         toastError(success.message);
  //       }
  //     },
  //     (fail) => {
  //       toastError(fail.message);
  //     }
  //   );
  // };
  return (
    <div className="global-component">
      <LandingPage />
      <Items />
    </div>
  );
}

export default Global;
