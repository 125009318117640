const BuyIcon = () => {
    return (
        <svg className="recycle-nav__nav-icon" fill="#ffffff" width="23" height="41" viewBox="0 0 23 41" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2707_48645)">
                <path d="M10.0033 2.5L3.75325 10H7.50325V16.25H12.5033V10H16.2533L10.0033 2.5ZM26.8783 2.5C23.7533 2.5 23.7533 2.5 23.7533 5.6207V18.125C23.7533 21.25 23.7533 21.25 26.8783 21.25H33.1283C36.2533 21.25 36.2533 21.25 36.2533 18.125V5.625C36.2533 2.5 36.2533 2.5 33.1283 2.5H26.8783ZM13.7608 18.5081C13.3483 18.5175 12.9629 18.697 12.4373 19.2578L11.9247 19.8023L11.3924 19.2773C10.8153 18.7085 10.3599 18.5515 9.89825 18.5718C9.43669 18.5921 8.89396 18.8347 8.28693 19.326L7.80107 19.7191L7.33966 19.2991C6.64435 18.6701 6.05177 18.4837 5.48419 18.513C5.07107 18.5345 4.64239 18.6901 4.21466 18.9623L7.41536 24.7191C8.62271 25.4575 11.0898 25.7354 12.7865 24.8804L15.8797 19.1502C15.293 18.911 14.7657 18.6659 14.3099 18.5718C14.1449 18.5377 13.9917 18.5126 13.8435 18.5081C13.8159 18.5074 13.7884 18.5074 13.7608 18.5081ZM27.5033 23.75V30H23.7533L30.0033 37.5L36.2533 30H32.5033V23.75H27.5033ZM6.73177 26.023C5.32279 27.0184 4.19146 28.7354 3.75325 31.25C3.32864 33.6872 3.99755 35.3331 5.18146 36.4549C6.36536 37.5767 8.14255 38.1532 9.94708 38.1298C11.7518 38.1063 13.5515 37.4805 14.7615 36.3379C15.9717 35.1952 16.6561 33.5703 16.268 31.2451C15.8667 28.8422 14.8305 27.1711 13.5188 26.1597C11.2936 27.3359 8.51146 27.0859 6.73177 26.023Z" />
            </g>
            <defs>
                <clipPath id="clip0_2707_48645">
                    <rect width="23" height="40" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default BuyIcon;