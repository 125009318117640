const OrdersIcon = () => {
    return (
        <svg className="recycle-nav__nav-icon" width="24" height="24" viewBox="0 0 41 40" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_354_7918)">
                <path d="M40.1168 8.15594L32.3046 0.343281C32.0848 0.123438 31.7867 0 31.4759 0H13.5068C11.5683 0 9.99121 1.57711 9.99121 3.51562V18.9717C4.72512 19.5566 0.616211 24.0337 0.616211 29.4531C0.616211 35.2687 5.34754 40 11.1631 40H36.9443C38.8829 40 40.46 38.4229 40.46 36.4844V8.98461C40.46 8.67375 40.3365 8.37578 40.1168 8.15594ZM32.6475 4.00102L36.4589 7.8125H32.6475V4.00102ZM2.95996 29.4531C2.95996 24.9299 6.63988 21.25 11.1631 21.25C15.6863 21.25 19.3662 24.9299 19.3662 29.4531C19.3662 33.9763 15.6863 37.6562 11.1631 37.6562C6.63988 37.6562 2.95996 33.9763 2.95996 29.4531ZM36.9443 37.6562H17.7841C19.8906 35.9527 21.3301 33.4563 21.6446 30.625H33.8193C34.4665 30.625 34.9912 30.1003 34.9912 29.4531C34.9912 28.8059 34.4665 28.2812 33.8193 28.2812H21.6445C21.4534 26.5609 20.8473 24.9641 19.9282 23.5938H33.8193C34.4665 23.5938 34.9912 23.0691 34.9912 22.4219C34.9912 21.7747 34.4665 21.25 33.8193 21.25H17.7841C16.259 20.0167 14.3848 19.1994 12.335 18.9717V3.51562C12.335 2.86945 12.8607 2.34375 13.5068 2.34375H30.3037V8.98438C30.3037 9.63156 30.8284 10.1562 31.4756 10.1562H38.1162V36.4844C38.1162 37.1305 37.5905 37.6562 36.9443 37.6562Z"  />
                <path d="M33.8193 14.2188H16.6318C15.9846 14.2188 15.46 14.7434 15.46 15.3906C15.46 16.0378 15.9846 16.5625 16.6318 16.5625H33.8193C34.4665 16.5625 34.9912 16.0378 34.9912 15.3906C34.9912 14.7434 34.4665 14.2188 33.8193 14.2188Z"  />
                <path d="M14.2881 30.625C14.9353 30.625 15.46 30.1003 15.46 29.4531C15.46 28.8059 14.9353 28.2812 14.2881 28.2812H12.335V24.7656C12.335 24.1184 11.8103 23.5938 11.1631 23.5938C10.5159 23.5938 9.99121 24.1184 9.99121 24.7656V29.4531C9.99121 30.1003 10.5159 30.625 11.1631 30.625H14.2881Z"  />
            </g>
            <defs>
                <clipPath id="clip0_354_7918">
                    <rect width="40" height="40"  transform="translate(0.538086)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default OrdersIcon;