import { Radio } from "antd";
import { useTranslation } from "react-i18next";

const DrawerTabs = ({ active, onTabChange }) => {
    const { t } = useTranslation();

    return (
        <Radio.Group
            name="tabs"
            defaultValue={1}
            optionType="button"
            buttonStyle="solid"
            value={active}
            onChange={onTabChange}
            className="auction-requests-drawer__tabs-holder d-flex justify-content-between align-items-center gap-3 px-3 px-md-1 px-lg-3 mb-3"
        >
            <Radio value={1} className="fsize-5">{t("auctionRequests.request")}</Radio>
            <Radio value={2} className="fsize-5">{t("auctionRequests.myAuctions")}</Radio>
        </Radio.Group>
    );
}

export default DrawerTabs;