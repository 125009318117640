import { useTranslation } from "react-i18next";

const BalanceRow = ({ data }) => {
    const { t } = useTranslation();

    return (
        <div className="buy-and-supply__balance-row d-flex align-items-stretch justify-content-between">
            <p className="mb-0 fsize-5 fw-bold text-white align-self-center">{data?.type}</p>
            <div className="buy-and-supply__balance-row__value-holder green-text fw-bold fsize-7 d-flex justify-content-center align-items-center">{data?.amountInKilo} {t("buyAndSupply.kilo")}</div>
        </div>
    );
}

export default BalanceRow;