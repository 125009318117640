import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../../constants/_routes";
import { logout } from "../../../store/authorization";

function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    navigate(ROUTES.SIGNIN);
  }, [navigate, dispatch]);
  return <></>;
}

export default Logout;
