import { useTranslation } from "react-i18next";
import { Button, Modal } from "antd";

const ConfirmBuyModal = ({
    open,
    onCancel,
    purchasePrice,
    diffPrice,
    onBuy,
    submitting,
    selectedItem,
    purchasedQuantity
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            centered
            width={
                window.innerWidth >= 1440 ?
                    "60%" : window.innerWidth > 767 ?
                        "70%" : "100%"
            }
            destroyOnClose={true}
            maskClosable={false}
            open={open}
            onCancel={onCancel}
            closable={true}
            footer={false}
        >
            <p className="text-center fsize-8 text--secondary-8 fw-bold">
                {`${t("storeItems.confirmMsg.1")} ${purchasePrice > 0 ? purchasePrice?.toFixed(2) : ""} ${t(`storeItems.confirmMsg.${diffPrice < 0 ? "pay" : "recieve"}`)} ${diffPrice < 0 ? -diffPrice?.toFixed(2) : diffPrice > 0 ? diffPrice?.toFixed(2) : ""} ${t("storeItems.confirmMsg.3")}`}
            </p>
            <Button
                loading={submitting}
                className="items--store-item__buy-btn d-block mx-auto border-0 outline-none shadow-none fsize-6 fw-bold text-white text-center px-4"
                htmlType="button"
                onClick={() => onBuy(selectedItem, purchasedQuantity)}
            >
                {t("storeItems.confirm")}
            </Button>
        </Modal>
    );
}

export default ConfirmBuyModal;