import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { getBuyAndSupplyPendingOrders, getBuyItems } from "../../../network";
import { toastError } from '../../../helpers/toasters';

// components
import TabsHolder from "../TabsHolder";
import BuyCard from "./BuyCard";
import BuyModal from "./BuyModal";
import BalanceRow from "../BalanceRow";
import PendingOrderCard from "./PendingOrderCard";
import EmptyState from "../EmptyState";

const Buy = () => {
    const [activeTab, setActiveTab] = useState(1) // 1 --> Buy, 2 --> Balance, 3 --> Pending orders
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [renderMe, setRenderMe] = useState(false);
    const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const { dir } = useSelector((state) => state.language);

    const handleCancelModal = () => {
        setIsBuyModalOpen(false);
        setSelectedItem(null)
    }

    const handleSubmitting = () => {
        handleCancelModal();
        setRenderMe(!renderMe)
    }

    const handleOpenBuyModal = (data) => {
        setSelectedItem(data)
        setIsBuyModalOpen(true)
    }

    const handleTabChange = (e) => {
        setActiveTab(e.target.value)
        setLoading(true)
    }

    useEffect(() => {
        if (activeTab === 3) {
            getBuyAndSupplyPendingOrders(
                (res) => {
                    setLoading(false)
                    if (res.success) {
                        setData(res.data)
                    } else {
                        toastError(res.message)
                    }
                },
                (res) => {
                    setLoading(false)
                    toastError(res.message)
                }
            )
        } else {
            getBuyItems(
                (res) => {
                    setLoading(false)
                    if (res.success) {
                        if (activeTab === 1) {
                            setData(res.data.items)
                        } else if (activeTab === 2) {
                            setData(res.data.userBuyAndSupplyBuyBalance)
                        }
                    } else {
                        toastError(res.message)
                    }
                },
                (res) => {
                    setLoading(false)
                    toastError(res.message)
                }
            )
        }
    }, [activeTab, renderMe, dir])

    return (
        <>
            <div dir="ltr" className="buy-and-supply__header d-flex flex-column justify-content-end">
                <TabsHolder active={activeTab} mode={"buy"} onTabChange={handleTabChange} />
            </div>
            <div className={`buy-and-supply__items-holder ${activeTab === 2 ? "buy-and-supply__items-holder--balance" : ""}`}>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center pt-5">
                        <Spin size="large" />
                    </div>
                ) : data?.length === 0 ? (
                    <EmptyState mode="buy" data={activeTab === 1 ? "items" : activeTab === 2 ? "balance" : "pending orders"} />
                ) : (
                    <div className={`${activeTab === 1 ? "buy-and-supply__items-holder__items" : activeTab === 2 ? "buy-and-supply__balance-holder" : "buy-and-supply__pending-orders-holder"} row m-0`}>
                        {activeTab === 1 ? (
                            data?.map((ele, indx) => (
                                <div key={ele.itemId} className={`col-lg-6 ${indx % 2 === 0 ? dir === "ltr" ? "ps-lg-0" : "pe-lg-0" : dir === "ltr" ? "pe-lg-0" : "ps-lg-0"}`}>
                                    <BuyCard data={ele} onBuy={handleOpenBuyModal} />
                                </div>
                            ))
                        ) : activeTab === 2 ? (
                            data?.map((ele) => (
                                <div key={ele.typeId} className="col-12 px-0">
                                    <BalanceRow data={ele} />
                                </div>
                            ))
                        ) : (
                            data?.map((ele, indx) => (
                                <div key={ele.id} className={`col-lg-6 ${indx % 2 === 0 ? dir === "ltr" ? "ps-lg-0" : "pe-lg-0" : dir === "ltr" ? "pe-lg-0" : "ps-lg-0"}`}>
                                    <PendingOrderCard data={ele} />
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
            {isBuyModalOpen && (
                <BuyModal
                    open={isBuyModalOpen}
                    onCancel={handleCancelModal}
                    selectedItem={selectedItem}
                    onSubmit={handleSubmitting}
                />
            )}
        </>
    );
}

export default Buy;