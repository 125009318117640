import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Form, InputNumber } from 'antd';
import { baseUrl } from '../../services';

// assets
import defaultProductImg from "../../assets/images/default-product-img.png";

const Item = ({
    item,
    onPayItem,
    onExchangeItem,
    luckyHours,
    storeItem,
    onSubmit,
    loading,
    submitting,
    redirectedFromOffers
}) => {
    const [totalPrice, setTotalPrice] = useState(0);
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const handlePreventLetters = (e) => {
        if (e.key === "Backspace" || (e.key.startsWith("Arrow") && e.key !== "ArrowUp" && e.key !== "ArrowDown")) return;
        if (/\D+/gi.test(e.key)) {
            e.preventDefault();
        }
    };

    const validateQuantityInput = (_, value) => {
        if (!value && value !== 0) {
            setTotalPrice(0)
            return Promise.reject(t("storeItems.requiredQuantityMsg"));
        } else if (value < 1) {
            setTotalPrice(0)
            return Promise.reject(t("storeItems.minQuantityMsg"));
        } else if (value > item.quantity) {
            setTotalPrice(0)
            return Promise.reject(t("storeItems.notAvailableQuantityMsg"));
        } else {
            setTotalPrice(value * item.price)
            return Promise.resolve()
        }
    }

    return (
        <div className="items__card row mx-0 align-items-md-center">
            <div className="col-md-6 px-0">
                <div className="items__fig w-100 position-relative">
                    {luckyHours ? (
                        <span className='lucky-hours-section__item__offer-badge d-inline-block position-absolute start-0 d-flex align-items-center justify-content-center text-white text-center fw-bold fsize-11'>
                            {t("luckyHours.offer")}
                        </span>
                    ) : (
                        ""
                    )}
                    <img
                        className="items__img"
                        src={
                            item?.image?.length > 0
                                ? baseUrl + item.image
                                : defaultProductImg
                        }
                        alt=""
                    />
                </div>
            </div>
            <div className="items__text-holder col-md-6 px-0 px-md-3">
                <div className="flex-fill px-md-3 px-xxl-4 d-flex flex-column align-items-center">
                    <h2 className="items__paragraph fsize-4 text-uppercase text-center">
                        {storeItem ? item?.name : item?.description}
                    </h2>
                    <div className="row mx-0 mb-3 mb-md-4 align-self-stretch align-self-md-center">
                        <div className="col-6 pe-1 pe-lg-3 ps-0 d-flex justify-content-end">
                            <div className="items__cart h-100">
                                <span className="items__quantity fw-bold text-center fsize-6">
                                    {storeItem ? (
                                        item?.price
                                    ) : (
                                        item?.itemUnits[0]?.price ? item.itemUnits[0].price : ""
                                    )}
                                </span>
                                <span className="items__available fsize-10 text-center text-uppercase">
                                    {storeItem ? t("home.itemModal.priceUnit") : t("home.pricePerKilo")}
                                </span>
                            </div>
                        </div>
                        <div className="col-6 ps-1 ps-lg-3 pe-0 d-flex justify-content-start">
                            <div className="items__cart h-100">
                                <span className="items__quantity fw-semibold text-center d-block w-100 fsize-6">
                                    {storeItem ? (
                                        item?.quantity
                                    ) : (
                                        item?.quantityPerKilo ? item.quantityPerKilo : ""
                                    )}
                                </span>
                                <span className="items__available d-block w-100 fsize-10 text-center text-uppercase">
                                    {t("home.available")}
                                </span>
                            </div>
                        </div>
                        {storeItem && (
                            <div dir={dir} className="col-12 px-0 pt-4">
                                <p className="items--store-item__description fsize-11 fw-bold align-self-stretch mb-0">
                                    <span className='items--store-item__description__label position-relative'>
                                        <span className='fsize-9 '>&bull;</span> {t("storeItems.description")}:
                                    </span>
                                    {" "}
                                    <span className='main-dark-text'>{item?.description}</span>
                                </p>
                            </div>
                        )}
                    </div>
                    {storeItem ? (
                        <>
                            <Form
                                form={form}
                                className="align-self-stretch"
                                onFinish={(values) => onSubmit(item, values.quantity, totalPrice)}
                                initialValues={{ quantity: "" }}
                                disabled={submitting}
                            >
                                <p dir={dir} className='text--secondary-8 fw-bold fsize-12 mb-1'>{t("storeItems.enterQuantity")}</p>
                                <div dir={dir} className='d-flex align-items-start gap-3'>
                                    <Form.Item
                                        name="quantity"
                                        rules={[
                                            { validator: validateQuantityInput },
                                        ]}
                                        className='flex-fill'
                                    >
                                        <InputNumber
                                            keyboard={false}
                                            controls={false}
                                            className="items--store-item__buy-input d-block w-100 fsize-6"
                                            onKeyDown={handlePreventLetters}
                                        />
                                    </Form.Item>
                                    {redirectedFromOffers && (
                                        <Button
                                            loading={loading}
                                            className="items--store-item__buy-btn border-0 outline-none shadow-none fsize-6 fw-bold text-white text-center px-4"
                                            htmlType="submit"
                                            disabled={submitting && !loading}
                                        >
                                            {t("storeItems.buy")}
                                        </Button>
                                    )}
                                </div>
                                {!redirectedFromOffers && (
                                    <div className="row m-0 align-items-center mb-3 mb-lg-4 px-xxl-3">
                                        <div className='col-6 h-100 px-2 px-xl-3'>
                                            <Button
                                                className="items--store-item__exchange-btn d-block w-100 h-100 bg-transparent outline-none shadow-none fsize-8 fw-bold green-text text-center px-2"
                                                onClick={() => {
                                                    if (!form.isFieldTouched("quantity")) {
                                                        form.validateFields(["quantity"]);
                                                        return;
                                                    } else if (form.getFieldError("quantity").length > 0) {
                                                        return
                                                    }
                                                    onExchangeItem(item, form.getFieldValue("quantity"))
                                                }}
                                            >
                                                {t("storeItems.exchange")}
                                            </Button>
                                        </div>
                                        <div className='col-6 h-100 px-2 px-xl-3'>
                                            <Button
                                                loading={loading}
                                                className="items--store-item__buy-btn d-block w-100 h-100 outline-none shadow-none fsize-8 fw-bold text-white text-center px-2"
                                                disabled={submitting && !loading}
                                                htmlType='submit'
                                            >
                                                {t("storeItems.buy")}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </Form>
                            {totalPrice ? (
                                <p dir={dir} className='items--store-item__total-price mb-0 text--secondary-8 fw-bold fsize-12'>
                                    {`${t("storeItems.totalPrice")}: ${totalPrice.toFixed(2)} ${t("notificationModal.egp")}`}
                                </p>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        <>
                            <Button
                                className="items__buy-btn fsize-8 h-auto py-2 py-xxl-3 mb-3"
                                onClick={() => onPayItem(item)}
                            >
                                {t("home.buyItem")}
                            </Button>
                            <Button
                                className="items__buy-btn items__exchange-btn fsize-8 h-auto py-2 py-xxl-3"
                                onClick={() => onExchangeItem(item)}
                            >
                                {t("home.exchangeItem")}
                            </Button>
                            {item?.minimumCharge && (
                                <div
                                    className="minimum-charge"
                                    style={{ color: "red" }}
                                >
                                    {item.minimumCharge}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Item;