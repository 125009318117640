import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { getLuckyHoursItem } from '../../network';
import { toastError } from '../../helpers/toasters';

// assets
import emptyState from "../../assets/images/no-lucky-hours.png";

// components
import ExchangeModal from "../Items/ExchangeModal";
import ItemModal from "../Items/ItemModal";
import Item from '../Items/Item';

const LuckyHour = () => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState(null);
    const [renderMe, setRenderMe] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isExchangeModalOpen, setIsExchangeModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const { lang, dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    const handlePayItem = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const handleExhchangeItem = (item) => {
        setSelectedItem(item);
        setIsExchangeModalOpen(true);
    };

    const handleCancel = (renderItems) => {
        setIsModalOpen(false);
        if (renderItems) {
            setRenderMe((prev) => !prev);
        }
    };

    const handleCancelExchangeModal = (renderItems) => {
        setIsExchangeModalOpen(false);
        if (renderItems) {
            setRenderMe((prev) => !prev);
        }
    };

    useEffect(() => {
        getLuckyHoursItem((res) => {
            setLoading(false);
            if (res.success) {
                setItems(res.data);
                setLoading(false);
            } else {
                toastError(res.message);
            }
        }, (res) => {
            setLoading(false);
            toastError(res.message);
        })
    }, [lang, renderMe])

    return (
        <section className="mct-section items-section lucky-hours-section d-flex justify-content-center">
            <Spin size="large" className="w-100" spinning={loading}>
                {items ? (
                    items.length > 0 ? (
                        <>
                            <h1 dir={dir} className='green-text fw-bold fsize-4 mb-4 mb-lg-5'>{t("luckyHours.luckyHoursItems")} ({items.length})</h1>
                            <div className="items d-flex flex-column">
                                {items.map((item) => {
                                    return (
                                        <Item
                                            key={item.itemId}
                                            item={item}
                                            onPayItem={handlePayItem}
                                            onExchangeItem={handleExhchangeItem}
                                            luckyHours={true}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    ) : (
                        <div className="d-flex pt-5 flex-column align-items-center">
                            <img
                                src={emptyState}
                                alt=""
                                className="lucky-hours-section__empty-state-img img-fluid d-block mb-3 mb-lg-4"
                            />
                            <h2 className="fsize-5 text-center mb-0 text-white">
                                {t("luckyHours.noOffers")}
                            </h2>
                        </div>
                    )
                ) : (
                    ""
                )}
            </Spin>
            {isModalOpen && (
                <ItemModal
                    isModalOpen={isModalOpen}
                    onCancel={handleCancel}
                    selectedItem={selectedItem}
                    luckyHours={true}
                    onNewData={(data) => {
                        const updatedSelectedItem = data.find((ele) => ele.itemId === selectedItem.itemId);
                        setSelectedItem(updatedSelectedItem)
                        setItems(data)
                    }}
                />
            )}
            {isExchangeModalOpen && (
                <ExchangeModal
                    isModalOpen={isExchangeModalOpen}
                    onCancel={handleCancelExchangeModal}
                    selectedItem={selectedItem}
                />
            )}
        </section>
    );
}

export default LuckyHour;