import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

const PendingOrderCard = ({ data }) => {
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    return (
        <article className="buy-and-supply__pending-orders-holder__pending-order-card h-100 d-flex flex-column">
            <div className="buy-and-supply__pending-orders-holder__pending-order-card__first-holder">
                <h2 className="green-text fsize-8 fw-bold mb-0">{data?.item}</h2>
                <div className="buy-and-supply__pending-orders-holder__pending-order-card__first-holder__data-holder d-flex flex-column justify-content-between">
                    <div dir="ltr" className="buy-and-supply__pending-orders-holder__pending-order-card__first-holder__data-holder_boxes-holder d-flex justify-content-center align-items-center">
                        <div dir={dir} className="d-flex flex-column justify-content-center align-items-center">
                            <div className="buy-and-supply__pending-orders-holder__pending-order-card__first-holder__data-holder_boxes-holder__value text-white fw-700 fsize-6 d-flex align-items-center justify-content-center gap-2">
                                <span className="d-block text-truncate">{data?.quantity}</span>
                                <span>{t("buyAndSupply.kilo")}</span>
                            </div>
                            <span className="text-white fw-500 fsize-12 text-center">{t("buyAndSupply.quantity")}</span>
                        </div>
                        <div dir={dir} className="d-flex flex-column justify-content-center align-items-center">
                            <div className="buy-and-supply__pending-orders-holder__pending-order-card__first-holder__data-holder_boxes-holder__value text-white fw-700 fsize-6 d-flex align-items-center justify-content-center gap-2">
                                <span className="d-block text-truncate">{data?.unitPrice}</span>
                                <span>{t("buyAndSupply.egp")}</span>
                            </div>
                            <span className="text-white fw-500 fsize-12 text-center">{t("buyAndSupply.price")}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="buy-and-supply__pending-orders-holder__pending-order-card__second-holder">
                <div className="d-flex justify-content-between align-items-center gap-2 green-text fw-500 fsize-8">
                    <span>{t("buyAndSupply.totalPriceBuy")}</span>
                    <span>{data?.price} {t("buyAndSupply.egp")}</span>
                </div>
                <p className="fw-bold fsize-11 text-center mb-0">{data ? new moment(data.creationDate, dir === "ltr" ? "DD MMM, YYYY" : "YYYY ,MMM DD").format("DD-MM-YYYY") : ""}</p>
            </div>
        </article>
    );
}

export default PendingOrderCard;