import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spin } from "antd";

// assets
import emptyDataImg from "../../../assets/images/no-orders.png"

// components
import Order from "./Order";

const Pending = ({ allData }) => {
    const { dir } = useSelector((state) => state.language);
    const { t } = useTranslation();

    return (
        allData ?
            (allData.length > 0 ? (
                <div style={{ overflowY: 'auto', maxHeight: '38vh' }}>
                    <div dir={dir} className="orders d-flex flex-column gap-2 gap-xxl-3 pe-2">
                        {allData.map((order) => {
                            if (order?.remainingQuantity > 0) {
                                return (
                                    <Order key={order.id} order={order} />
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            ) : (
                <div>
                    <img src={emptyDataImg} alt="" className="orders__no-data-img d-block mx-auto mb-3 img-fluid" />
                    <h4 className="text-center fw-bold main-dark-text">{t('pendingOrders.noData')}</h4>
                </div>
            ))
            :
            <div className="d-flex justify-content-center py-5">
                <Spin />
            </div>
    );
}

export default Pending;